import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Box } from "gestalt";
import { createOfficialOpportunity } from "../../../Store/actions/projectActions";

import "gestalt/dist/gestalt.css";
import DraftOpportunityList from "./DraftOpportunityList";
import { Redirect } from "react-router-dom";

class ReviewerPage extends Component {
  state = {
    location: "",
    discipline: [],
    grade: "",
    type: []
  };

  render() {
    const { auth, opportunities} = this.props;

    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }

    let unreviewedOpportunities;
    if (opportunities) {
        //filter out all opportunities that have already been reviewed
        unreviewedOpportunities = opportunities.filter(opportunity => {
          return opportunity.authorId === auth.uid;
        });
      
    }
    return (
      <div>
        <Box
          color="white"
          shape="rounded"
          padding={3}
          display="flex"
          direction="row"
        >
          <Box
            color="white"
            shape="rounded"
            padding={3}
            display="flex"
            direction="column"
          />
          <div className="dashboard container">
            <div className="row">
              <div className="col s12 m12">
                <DraftOpportunityList opportunities={unreviewedOpportunities} />{" "}
              </div>
            </div>
          </div>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    opportunities: state.firestore.ordered.review,
    auth: state.firebase.auth,
    firebase: state.firebase,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOfficialOpportunity: project =>
      dispatch(createOfficialOpportunity(project))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    return [ {
      collection: "review",
      orderBy: ["createdAt", "desc"]
    }
  ]})
)(ReviewerPage);
