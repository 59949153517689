import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Spinner } from "gestalt";
import { compose } from "redux";
import { updateUserInfo } from "../Store/actions/authActions";

import "gestalt/dist/gestalt.css";

class ProfilePage extends Component {
  state = {
    firstTime: true,
    showSuccessToast: false,
    showErrorToast: false
  };

  componentDidMount() {
    window.$(".sidenav").sidenav();
  }

  render() {
    const { auth, profile } = this.props;

    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }

    if (!profile.isEmpty) {
      console.log("state: ", this.state, "profile: ", profile);

      if (this.state.firstTime) {
        this.setState({ ...profile, firstTime: false });
      }
      return (
        <div>
          <div className="container s6">
            <div class="card">
              <form onSubmit={this.handleSubmit} className="white">
                <h5 className="teal-text center">
                  <strong>Profile</strong>
                </h5>
                <div className="input-field">
                  <i class=" teal-text material-icons prefix">account_circle</i>
                  <input
                    id="firstName"
                    type="text"
                    class="active"
                    placeholder=""
                    required
                    defaultValue={this.state.firstName}
                    onChange={this.handleTextAreaChange}
                  />
                </div>
                <div className="input-field">
                  <i class="teal-text material-icons prefix">email</i>
                  <input
                    type="email"
                    id="email"
                    required
                    placeholder=""
                    defaultValue={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>

                <h6 class="grey-text text-darken-2 center">
                  The following fields will help us connect you to more relevant
                  opportunities.
                </h6>
                <br />

                <div>
                  <span class="teal-text">
                    What grade is the person you are seeking opportunities for
                    in?
                  </span>
                  <p />
                  <select
                    class="browser-default"
                    onChange={this.handleGradeSelected}
                  >
                    <option
                      id="grade"
                      value={this.state.grade}
                      disabled
                      selected
                    >
                      {this.state.grade}
                    </option>
                    <option value="all">All</option>
                    <option value="4">4th</option>
                    <option value="5">5th</option>
                    <option value="6">6th</option>
                    <option value="7">7th</option>
                    <option value="8">8th</option>
                    <option value="9">9th (High School Freshman)</option>
                    <option value="10">10th (High School Sophomore)</option>
                    <option value="11">11th (High School Senior)</option>
                    <option value="12">12th (High School Freshman)</option>
                  </select>
                  <p />
                </div>


                <div>
                  <span class="teal-text">
                    What is the student's current unweighted GPA?
                  </span>
                  <p />
                  <select
                  defaultValue={this.state.gpa}
                    class="browser-default"
                    onChange={this.handleGPASelected}
                  >
                    <option
                      id="gpa"
                      disabled
                      selected
                    >
                   {this.state.gpa} 
                    </option>
                    <option value="all">All</option>
                    <option value="A">(A) 3.75 - 4.0  </option>
                    <option value="A-">(A-) 3.5 - 3.74 </option>
                    <option value="B+">(B+) 3.25 - 3.49 </option>
                    <option value="B">(B) 2.75 - 3.24 </option>
                    <option value="B-">(B-) 2.5 - 2.74 </option>
                    <option value="C+">(C+) 2.25 - 2.49 </option>
                    <option value="C">(C) 1.75 - 2.24 </option>
                    <option value="C-">(C-) 1.50 - 1.74 </option>
                    <option value="D+">(D+) 1.25 - 1.49</option>
                    <option value="D">(D) 0.75 - 1.24 </option>
                    <option value="Work in Progress">(Work In Progress) 0.0 - 0.74 </option>
                  </select>
                  <p />
                </div>

                <div>
                  <span class="teal-text"> What is your ethnicity?</span>
                  <select
                    class="browser-default"
                    onChange={this.handleEthnicityChanged}
                  >
                    <option
                      id="ethnicity"
                      disabled
                      selected
                    >
                      {this.state.ethnicity}
                    </option>
                    <option value="caucasian">Caucasian</option>
                    <option value="hispanic or latino/a">
                      Hispanic/Latino/a
                    </option>
                    <option value="black">Black/African</option>
                    <option value="native american">Native American</option>
                    <option value="asian">Asian</option>
                    <option value="pacific islander">Pacific Islander</option>
                    <option value="other">other</option>
                    <option value="prefer not to say">Prefer not to say</option>
                  </select>
                  <p />
                </div>

                <div class="input-field">
                  <span>Where are you located?</span>

                  <select
                    id="location"
                    class="browser-default"
                    value={this.state.location}
                    onChange={this.handleLocationSelected}
                  >
                    <option value="0" disabled selected>{this.state.location}</option>
                    <option value="International">International</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="Washington DC">Washington DC</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                </div>

                <div>
                  <label>
                    <input
                      id="freeLunch"
                      type="checkbox"
                      checked={this.state.freeLunch}
                      onChange={this.handleCheckBoxChange}
                    />
                    <span>
                      Please check this box if you or your child receive free
                      lunch.
                    </span>
                  </label>
                </div>

                <br />

                <div class="row center">
                  <div className="input-field">
                    <button
                      class="waves-effect waves-light btn-large z-depth-3"
                      onClick={this.onSubmitClicked}
                    >
                      Save
                    </button>
                    {this.state.error ? (
                      <span class="red-text center">
                        {" "}
                        *Please make sure to fill out all required fields*
                      </span>
                    ) : null}
                    {this.state.showSuccessToast ? (
                      <div>
                        <span class="teal-text center">
                          {" "}
                          *Profile Updated Successfully*
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container center">
          <Spinner show={true} />
        </div>
      );
    }
  }

  handleEthnicityChanged = e => {
    this.setState({ ethnicity: e.target.value });
  };

  handleLocationSelected = e => {
    this.setState({ location: e.target.value });
  };

  handleGPASelected = e => {
    this.setState({ gpa: e.target.value });
  };

  handleCheckBoxChange = e => {
    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.checked
    });
  };

  handleTextAreaChange = e => {
    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.value
    });
  };

  handleGradeSelected = e => {
    this.setState({ grade: e.target.value });
  };

  handleStateSelected = e => {
    this.setState({ status: e.target.value });
  };

  onSubmitClicked = e => {
    e.preventDefault();

    this.setState({ error: false, firstTime: true, showSuccessToast: false });
    this.props.updateUserInfo(
      this.state,
      this.refreshPage,
      this.showErrorToast
    );
  };

  refreshPage = () => {
    this.setState({ showErrorToast: false });
    this.setState({ showSuccessToast: true });
    this.props.history.push("/search");
  };

  showErrorToast = () => {
    this.setState({ showErrorToast: true });
    this.setState({ showSuccessToast: false });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserInfo: (user, refresh, error) =>
      dispatch(updateUserInfo(user, refresh, error))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProfilePage);
