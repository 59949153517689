const initState = {};

const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_PROJECT":
      console.log("created Project", action.project);
      return state;
    case "FETCH_EVENTS":
      console.log("fetched events", action.payload);
      return action;
    case "UPDATE_PROJECT":
      console.log("updated project", action.project);
      return state;
    case "CREATE_SAVED_ITEM":
      console.log("created saved item", action.project);
      return state;
    case "UPDATE_SAVED_ITEM":
      console.log("updated saved item", action.project);
      return state;
    case "UPDATE_STUDENTS":
      console.log("updated students for project", action.project);
      return state;
    case "UPDATE_STUDENTS_ERROR":
      console.log("updated students error", action.project);
      return state;
    case "CREATE_STUDENTS":
      console.log("updated students for project", action.project);
      return state;
    case "CREATE_STUDENTS_ERROR":
      console.log("updated students error", action.project);
      return state;
    case "UPDATE_PROJECT_ERROR":
      console.log("updated project error", action.project);
      return state;
    case "CREATE_PROJECT_ERROR":
      console.log("create project error", action.error);
      return state;
      case "CREATE_SAVED_ITEM_ERROR":
      console.log("created saved item error", action.project);
      return state;
    default:
      return state;
  }
};

export default projectReducer;
 