const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      console.log("login failed");
      return { ...state, authError: "Login Failed" };
    case "LOGIN_SUCCESS":
      console.log("login success", state);
      return {
        ...state,
        authError: null
      };
    case "SIGNUP_SUCCESS":
      console.log("signup success");
      return {
        ...state,
        authError: null
      };
    case "SIGNUP_ERROR":
      console.log("signup failure");
      return {
        ...state,
        authError: action.err.message
      };
    case "PROFILE_UPDATE_SUCCESS":
      console.log("profile update success");
      return {
        ...state,
        authError: null
      };
    case "PROFILE_UPDATE_ERROR":
      console.log("profile update failure");
      return {
        ...state,
        authError: action.err.message
      };
    case "SIGN_OUT_SUCCESS":
      console.log("Signout success");
      return state;
    default:
      return state;
  }
};

export default authReducer;
