import React, { Component } from "react";
import {Switch, Route } from "react-router-dom";
import NavBar from "./Components/Layouts/Navbar";
import SearchDashboard from "./Components/Dashboard/SearchDashboard";
import SignIn from "./Components/Auth/SignIn";
import SignUp from "./Components/Auth/SignUp";
import AddProgram from "./Components/Dashboard/AddProgram";
import ReviewPage from "./Components/Projects/ReviewState/ReviewPage";
import EditProgram from "./Components/Projects/EditProgram";

import DraftOpportunityDetails from "./Components/Projects/ReviewState/DraftOpportunityDetails";
import EditDraftOpportunity from "./Components/Projects/ReviewState/EditDraftOpportunity";
import OpportunityDetails from "./Components/Projects/OpportunityDetails";
import ReviewerPage from "./Components/Projects/ReviewState/ReviewerPage";
import TermsOfUse from "./Components/TermsOfUse";
import PrivacyPolicy from "./Components/Layouts/PrivacyPolicy";
import EditProfile from "./Components/Layouts/EditProfile";
import SavedProjects from "./Components/Projects/SavedProjects";
import ProfilePage from "./Components/ProfilePage";
import ScrollIntoView from "./Components/ScrollIntoView";
import UpcomingPage from "./Components/Projects/ReviewState/UpcomingPage";

class App extends Component {
  render() {
    return (
      <div>
        <div className="App">
          <div className="App-header">
            <NavBar />
          </div>
          <main>
            <Switch>
              <Route path="/TermsOfUse" component={ScrollIntoView(TermsOfUse)} />
              <Route path="/PrivacyPolicy" component={ScrollIntoView(PrivacyPolicy)} />
              <Route path="/EditProfile" component={EditProfile} />
              <Route exact path="/search" component={ScrollIntoView(SearchDashboard)} />
              <Route exact path="/" component={ScrollIntoView(SearchDashboard)} />
              <Route exact path="/AddProgram" component={AddProgram} />
              <Route exact path="/profilePage" component={ProfilePage} />
              <Route exact path="/ReviewPage" component={ReviewPage} />
              <Route exact path="/Upcoming" component={UpcomingPage} />
              <Route exact path="/Saved" component={SavedProjects} />
              <Route exact path="/ReviewerDash" component={ReviewerPage} />
              <Route
                exact
                path="/review/:id"
                component={DraftOpportunityDetails}
              />
              <Route
                exact
                path="/review/edit/:id"
                component={EditDraftOpportunity}
              />
              <Route
                exact
                path="/opportunities/:programName/:id"
                component={OpportunityDetails}
              />
              <Route
                exact
                path="/opportunities/edit/:id"
                component={EditProgram}
              />
              <Route path="/signin" component={ScrollIntoView(SignIn)} />
              <Route path="/signup" component={ScrollIntoView(SignUp)}  />
            </Switch>
          </main>
        </div>
        {/* <div className="App-footer">
            <Footer />
          </div> */}
      </div>
    );
  }
}

export default App;
