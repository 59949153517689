import React from "react";
import OpportunnitySummary from "../OpportunitySummary";
import { Link } from "react-router-dom";

const DraftOpportunityList = ({ opportunities, saveItem, isOwner }) => {
  return (
    <div className="project-list section">
    
    {opportunities ? <p>{opportunities.length + " listed"}</p>: null}
      {opportunities &&
        opportunities.map(opportunity => {
          return (
            <Link to={"/review/" + opportunity.id}>
              <OpportunnitySummary opportunity={opportunity} saveAction= {saveItem} key={opportunity.id} isReview={true}  isOwner = {isOwner}/>
              {/* here add extra input and button to add a day */}
              <h1></h1>
            </Link>
          );
        })}
    </div>
  );
};

export default DraftOpportunityList;
