import React, { Component } from "react";
import { connect } from "react-redux";
import { createOpportunity } from "../../Store/actions/projectActions";

import "gestalt/dist/gestalt.css";

class AddProgram extends Component {
  state = {
    programName: "",
    organizationName: "",
    programDescription: "",
    programUrl: "",
    programCost: "",
    scholarships: false,
    rollingAdmissions: false,
    free: false,
    minorities: false,
    women: false,
    online: false,
    lowIncome: false,
    paid: false,
    dueDate: "",
    openDate: "",
    locations: [],
    discipline: [],
    length: [],
    grades: [],
    type: [],
    showError: false
  };
 
  componentDidMount() {
    window.$(".sidenav").sidenav();
  }

  render() {
    return (
      <div class="container">
        <div class="card s12 m6">
          <form>
        <h5 class="teal-text center">Enter A New Program: </h5>

            <div class="input-field col">
              <input
                id="organizationName"
                type="text"
                class="validate"
                required
                onChange={this.handleTextAreaChange}
              />
              <label for="organizationName">
                Organization Name
              </label>
            </div>
            <br />

            <div class="input-field col s6">
              <input
                id="programName"
                type="text"
                class="validate"
                required
                onChange={this.handleTextAreaChange}
              />
              <label for="programName">Program Name</label>
            </div>

            <br />

              <div class="input-field col s12">
                <textarea
                  id="programDescription"
                  class="materialize-textarea"
                  required
                  onChange={this.handleTextAreaChange}
                />
                <label for="programDescription">
                  Program Description{" "}
                </label>
            </div>

            <br />

            <div class="input-field col s6">
              <input
                id="programEmail"
                type="text"
                class="validate"
                required
                onChange={this.handleTextAreaChange}
              />
              <label for="programEmail">Program Email</label>
            </div>

            <br />

            <div class="input-field col s6">
              <label for="programUrl">
                Program website url ex;
                https://example.com.
              </label>
              <input
                type="url"
                name="url"
                id="programUrl"
                size="30"
                required
                onChange={this.handleTextAreaChange}
              />
            </div>

            <br />

            <div class="input-field col s6">
            {this.state.logo ? <img class="square" src={this.state.logo} alt="new" /> : null}

              <label for="logo">
                Program logo url: ex;
                https://example.com/logo.
              </label>
              <input
                type="url"
                name="logo"
                id="logo"
                required
                onChange={this.handleTextAreaChange}
              />
            </div>

            <br />

            <div class="input-field col s6">
              <input
                id="programCost"
                type="text"
                class="validate"
                onChange={this.handleTextAreaChange}
              />
              <label for="programCost" required>
                Program Cost.
              </label>
            </div>

            <br />

            <div class="input-field col s6">
              <input
                id="programGPA"
                type="text"
                class="validate"
                onChange={this.handleTextAreaChange}
              />
              <label for="programGPA" required>
                If the program requires a minimum GPA please add it here.
              </label>
            </div>

            <br />

            <p>
              <label>
                <input
                  id="lowIncome"
                  type="checkbox"
                  onChange={this.handleCheckBoxChange}
                />
                <span>Does this program focus on low income students?</span>
              </label>
            </p>

            <p>
              <label>
                <input
                  id="scholarships"
                  type="checkbox"
                  onChange={this.handleCheckBoxChange}
                />
                <span>
                  Does The Program Offer Scholarships or financial aid?
                </span>
              </label>
            </p>

            <p>
              <label>
                <input
                  id="free"
                  type="checkbox"
                  onChange={this.handleCheckBoxChange}
                />
                <span>Is the program free of charge?</span>
              </label>
            </p>

            <p>
              <label>
                <input
                  id="minorities"
                  type="checkbox"
                  onChange={this.handleCheckBoxChange}
                />
                <span>
                  Does this program focus on minorities or underrepresented
                  groups? For example, women, latino's, african american, etc
                </span>
              </label>
            </p>

            <p>
              <label>
                <input
                  id="women"
                  type="checkbox"
                  onChange={this.handleCheckBoxChange}
                />
                <span>Does this program focus on women groups?</span>
              </label>
            </p>
            
              <p>
              <label>
                <input
                  id="online"
                  type="checkbox"
                  onChange={this.handleCheckBoxChange}
                />
                <span>Is this program online for 2020?</span>
              </label>
            </p>

            <p>
              <label>
                <input
                  id="paid"
                  type="checkbox"
                  checked={this.state.paid}
                  onChange={this.handleCheckBoxChange}
                />
                <span>Does this program pay students?</span>
              </label>
            </p>

            <br />

            <div>
              <label for="openDate">
                Please enter when the application open if known. If not known,
                put the date for last year.
              </label>
              <input
                id="openDate"
                type="date"
                class="datepicker"
                onChange={this.handleTextAreaChange}
              />
            </div>

            <br />

            <div>
              <label for="dueDate">
                Please enter when the application is due if known. If not known,
                put the date for last year.
              </label>
              <input
                id="dueDate"
                type="date"
                class="datepicker"
                onChange={this.handleTextAreaChange}
              />
            </div>

            <br />

            <p>
              <label>
                <input
                  id="rollingAdmissions"
                  type="checkbox"
                  onChange={this.handleCheckBoxChange}
                />
                <span>Does The Program Have Rolling Admissions?</span>
              </label>
            </p>

            <br />

            <div class="row">
              <h5 class="center teal-text">
                Please enter the city and state if the program has only one
                location
              </h5>
              <div class="input-field col s6">
                <input
                  id="programCity"
                  type="text"
                  onChange={this.handleTextAreaChange}
                />
                <label for="programCity">
                  If just one location please enter city
                </label>
              </div>

              <div class="input-field col s6">
                <input
                  id="programState"
                  type="text"
                  onChange={this.handleTextAreaChange}
                />
                <label for="programState">
                  If just one location please enter state
                </label>
              </div>
            </div>

            <div class="input-field">
              <span>
                Required: Choose locations for this program. Select where this
                program is hosted. It could be more than one city.
              </span>

              <select
                class="browser-default"
                onChange={this.handleLocationChanged}
              >
                <option value="0" disabled selected />
                <option value="International">International</option>
                <option value="Nationwide">Nationwide</option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="Washington DC">Washington DC</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
              </select>
            </div>

            {this.state.locations.map(location => {
              return (
                <a
                  href="#"
                  class="btn  teal lighten-2"
                  onClick={() => this.handleRemoveLocation(location)}
                >
                  <span>{location}</span>
                  <i class="material-icons">clear</i>
                </a>
              );
            })}

            <div class="input-field">
              <span>
                Required: Choose grades for this program. It could be multiple
                grades. Please select all that are true.
              </span>
              <select
                required
                validate
                class="browser-default"
                onChange={this.handleGradeChanged}
              >
                <option value="0" disabled selected />
                <option value="k">Kindergarden</option>
                <option value="1">First Grade (1st)</option>
                <option value="2">Second Grade (2nd)</option>
                <option value="3">Third Grade (3rd)</option>
                <option value="4">Fourth Grade (4th)</option>
                <option value="5">Fifth Grade (5th)</option>
                <option value="6">Sixth Grade (6th)</option>
                <option value="7">Seventh Grade (7th)</option>
                <option value="8">Eight Grade (8th)</option>
                <option value="9">High School Freshman (9th)</option>
                <option value="10">High School Sophomore (10th)</option>
                <option value="11">High School Junior (11th)</option>
                <option value="12">High School Senior (12th)</option>
                <option value="college freshman">College Freshman </option>
                <option value="college sophomore">College Sophomore</option>
                <option value="college junior">College Junior </option>
                <option value="college senior">College  Senior </option>
              </select>
            </div>

            {this.state.grades.map(discipline => {
              return (
                <a
                  href="#"
                  class="btn  teal lighten-2"
                  onClick={() => this.handleRemoveGrade(discipline)}
                >
                  <span>{discipline}</span>
                  <i class="material-icons">clear</i>
                </a>
              );
            })}

            <div class="input-field">
              <span>
                Required: Choose disciplines for this program. It could be
                multiple disciplines.
              </span>
              <select
                class="browser-default"
                onChange={this.handleDisciplineChanged}
              >
                <option value="0" disabled selected />
  
                <option value="accounting">Accounting</option>
                <option value="architecture">Architecture</option>
                <option value="business">Business</option>
                <option value="college prep">College Prep</option>
                <option value="creative arts">Creative Arts</option>
                <option value="dance">Dance</option>
                <option value="design">Design</option>
                <option value="education">Education</option>
                <option value="engineering">Engineering</option>
                <option value="entrepreneurship">Entrepreneurship</option>
                <option value="english">English</option>
                <option value="finance">Finance</option>
                <option value="high school prep">High school prep</option>
                <option value="history">History</option>
                <option value="international affairs">
                  International Affairs
                </option>
                <option value="law">Law</option>
                <option value="leadership development">
                  Leadership Development
                </option>
                <option value="math">Math</option>
                <option value="medicine">Medicine</option>
                <option value="music">Music</option>
                <option value="outdoors">Outdoors</option>
                <option value="politics">Politics</option>
                <option value="research">Research</option>
                <option value="sat prep">SAT Prep</option>
                <option value="science">Science</option>
                <option value="self esteem">Self Esteem</option>
                <option value="social impact">Social Impact</option>
                <option value="sports">Sports</option>
                <option value="STEM">STEM</option>
                <option value="technology">Technology</option>
                <option value="theater">Theater</option>
                <option value="Volunteer">Volunteer</option>
                <option value="writing">Writing</option>
                <option value="other">other</option>
              </select>
              <p />

              {this.state.discipline.map(discipline => {
                return (
                  <a
                    href="#"
                    class="btn teal lighten-2"
                    onClick={() => this.removeDiscipline(discipline)}
                  >
                    <span>{discipline}</span>
                    <i class="material-icons">clear</i>
                  </a>
                );
              })}
            </div>

            <div class="input-field">
              <span>
                Required: Choose type for this program. It could be multiple
                types.
              </span>
              <select class="browser-default" onChange={this.handleTypeChanged}>
                ><option value="0" disabled selected />
                <option value="after school">After School</option>
                <option value="summer">Summer Break</option>
                <option value="winter">Winter Break</option>
                <option value="weekend">Weekend</option>
                <option value="year round">Year Round</option>
              </select>
              <p />
              {this.state.type.map(programType => {
                return (
                  <a
                    href="#"
                    class="btn  teal lighten-2"
                    onClick={() => this.removeType(programType)}
                  >
                    <span>{programType}</span>
                    <i class="material-icons">clear</i>
                  </a>
                );
              })}
            </div>

            <div class="input-field">
              <span>
                Required: Choose the length of this program. Select all that
                apply.
              </span>
              <select
                class="browser-default"
                onChange={this.handleLengthChanged}
              >
                <option value="0" disabled selected />
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
              </select>
              <p />

              {this.state.length.map(length => {
                return (
                  <a
                    href="#"
                    class="btn  teal lighten-2"
                    onClick={() => this.removeLength(length)}
                  >
                    <span>{length}</span>
                    <i class="material-icons">clear</i>
                  </a>
                );
              })}
            </div>

            <div class="right">
              <button
                class="btn waves-effect waves-light"
                type="submit"
                name="action"
                onClick={this.handleSubmit}
              >
                Submit For Review
                <i class="material-icons right">send</i>
              </button>
            </div>
            {this.state.showErrorToast ? (
              <div class="toast red">
                <div class="toast-header">Failed to Create</div>
                <div class="toast-body">
                  Please press the submit button again
                </div>
              </div>
            ) : null}
            <br/>
            <br/>
            <br/>
          </form>
          <div className="red-text center">
            {this.state.showError ? (
              <p>Please fill out all required fields</p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  handleCheckBoxChange = e => {
    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.checked
    });
  };

  handleTextAreaChange = e => {
    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.value
    });
  };

  removeDiscipline = programDiscipline => {
    let list = this.state.discipline.filter(type => {
      return type !== programDiscipline;
    });

    this.setState({ discipline: list });
  };

  removeType = programType => {
    let list = this.state.type.filter(type => {
      return type !== programType;
    });

    this.setState({ type: list });
  };

  handleLocationChanged = e => {
    const locationList = this.state.locations;
    locationList.push(e.target.value);

    this.setState({ locations: locationList });
  };

  handleLengthChanged = e => {
    const lengthList = this.state.length;
    lengthList.push(e.target.value);

    this.setState({ length: lengthList });
  };

  removeLength = programLength => {
    let list = this.state.length.filter(type => {
      return type !== programLength;
    });

    this.setState({ length: list });
  };

  handleRemoveLocation = location => {
    let list = this.state.locations.filter(type => {
      return type !== location;
    });
    this.setState({ locations: list });
  };

  handleGradeChanged = e => {
    const gradeList = this.state.grades;
    gradeList.push(e.target.value);

    this.setState({ grades: gradeList });
  };

  handleRemoveGrade = grade => {
    let list = this.state.grades.filter(type => {
      return type !== grade;
    });
    this.setState({ grades: list });
  };

  handleDisciplineChanged = e => {
    const disciplineList = this.state.discipline;
    disciplineList.push(e.target.value);
    this.setState({ discipline: disciplineList });
  };

  handleTypeChanged = e => {
    const typeList = this.state.type;
    if (!typeList.includes(e.target.value)) {
      typeList.push(e.target.value);
    }
    this.setState({ type: typeList });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (
      this.state.programName !== "" &&
      this.state.organizationName !== "" &&
      this.state.programDescription !== "" &&
      this.state.programUrl !== "" &&
      this.state.grades.length > 0 &&
      this.state.discipline.length > 0 &&
      this.state.locations.length > 0 &&
      this.state.type.length > 0
    ) {
      const program = {
        ...this.state
      };
      this.setState({ showError: false });
      this.props.createOpportunity(
        program,
        this.refreshPage,
        this.showErrorToast
      );
    } else {
      this.setState({ showError: true });
    }
  };

  refreshPage = () => {
    this.setState({ showErrorToast: false });

    //Send user to the homepage
    this.props.history.push("/reviewerDash");
  };

  showErrorToast = () => {
    this.setState({ showErrorToast: true });
  };
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    firebaseProfile: state.firebase.profile
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createOpportunity: (project, refreshPage, showErrorToast) =>
      dispatch(createOpportunity(project, refreshPage, showErrorToast))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProgram);
