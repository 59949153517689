import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import "gestalt/dist/gestalt.css";
import { Redirect } from "react-router-dom";

class EditProfile extends Component {
  state = {
    location: "",
    discipline: [],
    grade: "",
    type: []
  };

  render() {
    const { auth, profile } = this.props;

    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }

    if (!profile.isEmpty && !profile.owner) {
      return <Redirect to="/search" />;
    }
    return (
      <div class="container">
          <h1>Hello World</h1>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     createOfficialOpportunity: project =>
//       dispatch(createOfficialOpportunity(project))
//   };
// };

export default compose(
  connect(
    mapStateToProps
    // mapDispatchToProps
  )
)(EditProfile);
