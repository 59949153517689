import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import {
  updateProject,
  deleteProject,
} from "../../../Store/actions/projectActions";
import { NavLink } from "react-router-dom";
import "./DraftOpportunityDetails.css";
import AddToCalendar from "react-add-to-calendar";
import { Spinner } from "gestalt";

class DraftOpportunityDetails extends Component {
  handleSubmit = (review) => (e) => {
    e.preventDefault();

    this.props.createOfficialOpportunity(review, this.props.id);

    //Send user to the homepage
    if (this.props.profile.owner) {
      this.props.history.push("/ReviewPage");
    } else {
      this.props.history.push("/search");
    }
  };

  handleDelete = (review) => (e) => {
    e.preventDefault();
    this.props.deleteProject(review, this.props.id);
    //Send user to the homepage
    if (this.props.profile.owner) {
      this.props.history.push("/ReviewPage");
    } else {
      this.props.history.push("/search");
    }
  };

  addLesson = (e) => {
    const newArrayOfLessons = [...this.state.lessons, ""];
    this.setState({ lessons: newArrayOfLessons });
  };

  render() {
    let event = {
      title: "Sample Event",
      description: "This is the sample event provided as an example only",
      location: "Portland, OR",
      startTime: "2016-09-16T20:15:00-04:00",
      endTime: "2016-09-16T21:45:00-04:00",
    };

    const { review, auth, profile, id, firebase } = this.props;

    if (!auth.uid) return <Redirect to="/SignIn" />;
    if (review && profile) {
      if (!profile.owner && !profile.contributor)
        return <Redirect to="/search" />;
      console.log("Stateeeeeee", review);

      return (
        <div className="container section project-details">
          <div className="card z-depth-1">
            <div className="card-content">
              <span className="card-title">
                {review.organizationName + ":  " + review.programName}
              </span>
              <pre className="card-subtitle">
                <p>{review.programDescription}</p>
              </pre>
              <div>
                <br />
                {review.programCost !== "" && !review.programCost ? (
                  <p>Program Cost: {review.programCost}</p>
                ) : null}
                {review.programCost !== "" && review.scholarships ? (
                  <p>
                    <AddToCalendar event={event} />
                    <strong>Program Cost:</strong> {review.programCost}:
                    Scholarships Available
                  </p>
                ) : null}
                {review.locations.length > 1 ? (
                  <h6 className="grey-text">
                    Locations: {review.locations.join(", ")}
                  </h6>
                ) : (
                  <h6 className="grey-text">Location: {review.locations}</h6>
                )}
                {review.discipline.length > 1 ? (
                  <h6 className="grey-text">
                    Disciplines: {review.discipline.join(", ")}
                  </h6>
                ) : (
                  <h6 className="grey-text">Discipline: {review.discipline}</h6>
                )}
                {review.grades.length > 1 ? (
                  <h6 className="grey-text">
                    Grades: {review.grades.join(", ")}
                  </h6>
                ) : (
                  <h6 className="grey-text">Grade: {review.grades}</h6>
                )}
                {review.type.length > 1 ? (
                  <h6 className="grey-text">Types: {review.type.join(", ")}</h6>
                ) : (
                  <h6 className="grey-text">Type: {review.type}</h6>
                )}
                {review.openDate ? (
                  <h6 className="grey-text">
                    Application Open Date: {review.openDate}{" "}
                  </h6>
                ) : null}
                {review.dueDate ? (
                  <h6 className="grey-text">
                    Application Due Date: {review.dueDate}{" "}
                  </h6>
                ) : null}

                {review.online ? (
                  <p>
                    <label>
                      <input
                        id="online"
                        type="checkbox"
                        disabled="disabled"
                        checked={review.online}
                        onChange={this.handleCheckBoxChange}
                      />
                      <span>Online for 2020</span>
                    </label>
                  </p>
                ) : null}

                {review.rollingAdmissions ? (
                  <p>
                    <label>
                      <input
                        id="rollingAdmissions"
                        type="checkbox"
                        disabled="disabled"
                        checked={review.rollingAdmissions}
                        onChange={this.handleCheckBoxChange}
                      />
                      <span>Rolling Admissions</span>
                    </label>
                  </p>
                ) : null}

                {review.scholarships ? (
                  <p>
                    <label>
                      <input
                        id="scholarships"
                        type="checkbox"
                        disabled="disabled"
                        checked={review.scholarships}
                        onChange={this.handleCheckBoxChange}
                      />
                      <span>Offers Scholarships</span>
                    </label>
                  </p>
                ) : null}

                {review.women ? (
                  <p>
                    <label>
                      <input
                        id="women"
                        type="checkbox"
                        disabled="disabled"
                        checked={review.women}
                        onChange={this.handleCheckBoxChange}
                      />
                      <span>Focus on women</span>
                    </label>
                  </p>
                ) : null}

                {review.minorities ? (
                  <p>
                    <label>
                      <input
                        id="minorities"
                        type="checkbox"
                        disabled="disabled"
                        checked={review.minorities}
                        onChange={this.handleCheckBoxChange}
                      />
                      <span>
                        Focus on minorities and underrepresented groups
                      </span>
                    </label>
                  </p>
                ) : null}

                <br />

                <button className="btn teal darken-3 z-depth-3">
                  {" "}
                  <a
                    class="white-text"
                    target="_blank"
                    href={review.programUrl}
                  >
                    Visit Website
                  </a>{" "}
                </button>
              </div>
            </div>
            {profile.owner ? (
              <div className="card-action grey lighten-4 grey-text">
                <p className="grey-text">
                  {moment(review.createdAt.toDate()).calendar()}
                </p>
                <button
                  className="btn red darken-3 z-depth-3"
                  onClick={this.handleDelete(review)}
                >
                  Delete
                </button>
              </div>
            ) : null}

            {profile.reviewer || profile.owner || profile.contributor ? (
              <div className="input-field">
                <div class="right">
                  <NavLink to={"/review/edit/" + id}>
                    Edit Program Details
                  </NavLink>
                </div>
              </div>
            ) : null}
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return (
        <div className="container center">
          <Spinner show={true} />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const reviews = state.firestore.data.review;
  const review = reviews ? reviews[id] : null;
  return {
    id: ownProps.match.params.id,
    review: review,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    firebase: state.firebase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (opportunity, id) =>
      dispatch(updateProject(opportunity, id, true)),
    deleteProject: (opportunity, id) =>
      dispatch(deleteProject(opportunity, id, true)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "review" }])
)(DraftOpportunityDetails);
