import React from "react";
import moment from "moment";

const OpportunitySummary = ({ opportunity, isOwner }) => {
  return (
      <div
        class="card-panel"
        style={{
          height: 400,
          borderRadius: 30,
          overflow: "hidden"
        }}
      >
        <div
          class="teal lighten-3"
          style={{
            minHeight: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            src={opportunity.logo ? opportunity.logo : opportunity.logoUrl ? opportunity.logoUrl : null}
            style={{ maxWidth: 150, maxHeight: 150 }}
          />
        </div>
        <h6 class="center teal-text darken-3">{opportunity.programName}</h6>
        <p class="green-text darken-2">
          <strong>{opportunity.organizationName}</strong>
        </p>
        <div
        class="black-text"
          style={{
            height: "3em",
            lineHeight: "1em",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {opportunity.programDescription}
        </div>
        
        {/* <p style={{ fontStyle: "italic" }}>
          <strong>Open Date: </strong>
          {moment(opportunity.openDate).calendar()}
        </p>
        
         <p style={{ fontStyle: "italic" }}>
          <strong>Deadline: </strong>
          {moment(opportunity.dueDate).calendar()}
        </p>
         */}
      </div>
  );
};

export default OpportunitySummary;
