import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { signUp } from "../../Store/actions/authActions";
import { NavLink } from "react-router-dom";
import "./SignUp.css";

class SignUp extends Component {
  state = {
    email: "",
    password: "",
    firstName: "",
    status: "",
    ethnicity: "",
    grade: 0,
    gpa: 0,
    zipcode: 0,
    freeLunch: false,
    emailSignup: true,
    discipline: []
  };

  componentDidMount() {
    window.$(".sidenav").sidenav();
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log("state", this.state)
    if (this.state.ethnicity.length > 0 && this.state.grade.length > 0) {
      this.props.signUp(this.state);
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    const { auth, authError } = this.props;
    //TODO Figure out how to get the authError
    if (auth.uid) return <Redirect to="/search" />;
    return (
      <div>
        <div className="container s6">
          <div class="card">
            <form onSubmit={this.handleSubmit} className="white">
              <h5 className="teal-text center">
                <strong>Create An Account</strong>
              </h5>
              <p class="center">
                Already have an account? <NavLink to="/signin">Sign In</NavLink>
              </p>
              <p class="center">
                <strong>
                  Simply complete this quick profile and get instantly matched
                  to opportunities and programs around the country, it’s that
                  easy.{" "}
                </strong>
              </p>

              <div className="input-field">
                <i class="material-icons prefix">account_circle</i>

                <label htmlFor="firstName">Name</label>
                <input
                  type="text"
                  id="firstName"
                  required
                  onChange={this.handleChange}
                />
              </div>

              <div className="input-field">
                <i class="material-icons prefix">email</i>

                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  required
                  onChange={this.handleChange}
                />
              </div>

              <div className="input-field">
                <i class="material-icons prefix">vpn_key</i>

                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  required
                  onChange={this.handleChange}
                />
              </div>

              <div class="input-field col s6">
                <i class="material-icons prefix">phone</i>
                <input
                  id="telephone"
                  type="tel"
                  class="validate"
                  onChange={this.handleChange}
                />
                <label for="telephone">Telephone (optional)</label>
              </div>

              <div>
                <span>Are you a student, parent or teacher?</span>
                <p />
                <select
                  class="browser-default"
                  onChange={this.handleStateSelected}
                >
                  <option id="status" value="0" disabled selected>
                    Choose one
                  </option>
                  <option value="student">Student</option>
                  <option value="parent">Parent</option>
                  <option value="teacher">Teacher</option>
                  <option value="counselor">Counselor</option>
                  <option value="other status">other</option>
                </select>
              </div>
              <br />

              <h6 class="teal-text text-darken-2 center">
                The following fields will help us connect you to more relevant
                opportunities.
              </h6>
              <br />

              <div>
                <span>
                  What grade is the person you are seeking opportunities for in?
                </span>
                <p />
                <select
                  class="browser-default"
                  onChange={this.handleGradeSelected}
                >
                  <option id="grade" value="0" disabled selected>
                    Choose one
                  </option>
                  <option value="all">All</option>
                  <option value="4">4th</option>
                  <option value="5">5th</option>
                  <option value="6">6th</option>
                  <option value="7">7th</option>
                  <option value="8">8th</option>
                  <option value="9">9th (High School Freshman)</option>
                  <option value="10">10th (High School Sophomore)</option>
                  <option value="11">11th (High School Junior)</option>
                  <option value="12">12th (High School Senior)</option>
                </select>
                <p />
              </div>


              <div>
                  <span>
                    What is the student's current unweighted GPA?
                  </span>
                  <p />
                  <select
                    class="browser-default"
                    onChange={this.handleGPASelected}
                  >
                    <option
                      id="gpa"
                      value={this.state.gpas}
                      disabled
                      selected
                    >
                      Choose GPA Range
                    </option>
                    <option value="all">All</option>
                    <option value="A">(A) 3.75 - 4.0  </option>
                    <option value="A-">(A-) 3.5 - 3.74 </option>
                    <option value="B+">(B+) 3.25 - 3.49 </option>
                    <option value="B">(B) 2.75 - 3.24 </option>
                    <option value="B-">(B-) 2.5 - 2.74 </option>
                    <option value="C+">(C+) 2.25 - 2.49 </option>
                    <option value="C">(C) 1.75 - 2.24 </option>
                    <option value="C-">(C-) 1.50 - 1.74 </option>
                    <option value="D+">(D+) 1.25 - 1.49</option>
                    <option value="D">(D) 0.75 - 1.24 </option>
                    <option value="Work in Progress">(Work In Progress) 0.0 - 0.74 </option>
                  </select>
                  <p />
                </div>


              <div>
                <span> What is your ethnicity?</span>
                <p />

                <select
                  class="browser-default"
                  onChange={this.handleEthnicityChanged}
                >
                  <option id="ethnicity" value="0" disabled selected>
                    Ethnicity
                  </option>
                  <option value="caucasian">Caucasian</option>
                  <option value="hispanic or latino">Hispanic/Latino/a</option>
                  <option value="black">Black/African American</option>
                  <option value="native american">Native American</option>
                  <option value="asian">Asian</option>
                  <option value="pacific islander">Pacific Islander</option>
                  <option value="other">other</option>
                  <option value="prefer not to say">Prefer not to say</option>
                </select>
                <p />
              </div>

              <div class="input-field">
                <span>What subjects are you interested in exploring?</span>
                <select
                  class="browser-default"
                  onChange={this.handleDisciplineChanged}
                >
                  <option value="0" disabled selected>Choose All That Apply</option>
                  <option value="architecture">Architecture</option>
                  <option value="business">Business</option>
                  <option value="STEM">STEM</option>
                  <option value="college prep">College Prep</option>
                  <option value="creative arts">Creative Arts</option>
                  <option value="dance">Dance</option>
                  <option value="engineering">Engineering</option>
                  <option value="entrepreneurship">Entrepreneurship</option>
                  <option value="english">English</option>
                  <option value="high school prep">High school prep</option>
                  <option value="history">History</option>
                  <option value="international affairs">
                    International Affairs
                  </option>
                  <option value="law">Law</option>
                  <option value="leadership development">
                    Leadership Development
                  </option>
                  <option value="math">Math</option>
                  <option value="medicine">Medicine</option>
                  <option value="music">Music</option>
                  <option value="outdoors">Outdoors</option>
                  <option value="politics">Politics</option>
                  <option value="research">Research</option>
                  <option value="sat prep">SAT Prep</option>
                  <option value="science">Science</option>
                  <option value="self esteem">Self Esteem</option>
                  <option value="social impact">Social Impact</option>
                  <option value="sports">Sports</option>
                  <option value="technology">Technology</option>
                  <option value="theater">Theater</option>
                  <option value="Volunteer">Volunteer</option>
                  <option value="writing">Writing</option>
                  <option value="other">other</option>
                </select>

                {this.state.discipline.map(discipline => {
                  return (
                    <a
                      href="#"
                      class="btn teal lighten-2"
                      onClick={() => this.removeDiscipline(discipline)}
                      style={{ borderRadius: 30, margin: 3 }}
                    >
                      <span>{discipline}</span>
                      <i class="material-icons">clear</i>
                    </a>
                  );
                })}
              </div>

              <div class="input-field">
                <span>
                  Where are you located?
                </span>

                <select
                  class="browser-default"
                  onChange={this.handleLocationSelected}
                >
                  <option value="0" disabled selected>Select State</option>
                  <option value="International">International</option>
                  <option value="Alabama">Alabama</option>
                  <option value="Alaska">Alaska</option>
                  <option value="Arizona">Arizona</option>
                  <option value="Arkansas">Arkansas</option>
                  <option value="California">California</option>
                  <option value="Colorado">Colorado</option>
                  <option value="Connecticut">Connecticut</option>
                  <option value="Delaware">Delaware</option>
                  <option value="Florida">Florida</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Idaho">Idaho</option>
                  <option value="Illinois">Illinois</option>
                  <option value="Indiana">Indiana</option>
                  <option value="Iowa">Iowa</option>
                  <option value="Kansas">Kansas</option>
                  <option value="Kentucky">Kentucky</option>
                  <option value="Louisiana">Louisiana</option>
                  <option value="Maine">Maine</option>
                  <option value="Maryland">Maryland</option>
                  <option value="Massachusetts">Massachusetts</option>
                  <option value="Michigan">Michigan</option>
                  <option value="Minnesota">Minnesota</option>
                  <option value="Mississippi">Mississippi</option>
                  <option value="Missouri">Missouri</option>
                  <option value="Montana">Montana</option>
                  <option value="Nebraska">Nebraska</option>
                  <option value="Nevada">Nevada</option>
                  <option value="New Hampshire">New Hampshire</option>
                  <option value="New Jersey">New Jersey</option>
                  <option value="New Mexico">New Mexico</option>
                  <option value="New York">New York</option>
                  <option value="North Carolina">North Carolina</option>
                  <option value="North Dakota">North Dakota</option>
                  <option value="Ohio">Ohio</option>
                  <option value="Oklahoma">Oklahoma</option>
                  <option value="Oregon">Oregon</option>
                  <option value="Pennsylvania">Pennsylvania</option>
                  <option value="Rhode Island">Rhode Island</option>
                  <option value="South Carolina">South Carolina</option>
                  <option value="South Dakota">South Dakota</option>
                  <option value="Tennessee">Tennessee</option>
                  <option value="Texas">Texas</option>
                  <option value="Utah">Utah</option>
                  <option value="Vermont">Vermont</option>
                  <option value="Virginia">Virginia</option>
                  <option value="Washington">Washington</option>
                  <option value="Washington DC">Washington DC</option>
                  <option value="West Virginia">West Virginia</option>
                  <option value="Wisconsin">Wisconsin</option>
                  <option value="Wyoming">Wyoming</option>
                </select>
              </div>
              <br/>

              <div>
                <label>
                  <input
                    id="freeLunch"
                    type="checkbox"
                    onChange={this.handleCheckBoxChange}
                  />
                  <span>
                    Please check this box if you or your child receive free
                    lunch.
                  </span>
                </label>
              </div>

              <br />
              <div>
                <label>
                  <input
                    id="emailSignup"
                    type="checkbox"
                    checked="checked"
                    onChange={this.handleCheckBoxChange}
                  />
                  <span>
                    Receive updates on new opportunities, upcoming deadlines,
                    open applications and our biweekly newsletter
                  </span>
                </label>
              </div>

              <div class="row center">
                <div className="input-field">
                  <button
                    class="waves-effect waves-light btn-large z-depth-3"
                    onSubmit={this.handleSubmit}
                  >
                    Create Account
                  </button>
                  {authError ? <p> {authError}</p> : null}
                  {this.state.error ? (
                    <span class="red-text center">
                      {" "}
                      *Please make sure to fill out all required fields*
                    </span>
                  ) : null}
                </div>
                <p>
                  By creating an account you agree to the{" "}
                  <a href={"http://" + "scholarahead.com/privacypolicy"}>
                    Privacy Policy
                  </a>{" "}
                  and our{" "}
                  <a href={"http://" + "scholarahead.com/termsOfUse"}>
                    Terms of Use
                  </a>
                  .
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  handleEthnicityChanged = e => {
    this.setState({ ethnicity: e.target.value });
  };

  handleCheckBoxChange = e => {
    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.checked
    });
  };

  handleTextAreaChange = e => {
    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.value
    });
  };

  handleGradeSelected = e => {
    this.setState({ grade: e.target.value });
  };

  handleLocationSelected = e => {
    this.setState({ location: e.target.value });
  };

  handleGPASelected = e => {
    this.setState({ gpa: e.target.value });
  };

  handleStateSelected = e => {
    this.setState({ status: e.target.value });
  };

  handleDisciplineChanged = e => {
    const disciplineList = this.state.discipline;
    disciplineList.push(e.target.value);
    this.setState({ discipline: disciplineList });
  };

  removeDiscipline = programDiscipline => {
    let list = this.state.discipline.filter(type => {
      return type !== programDiscipline;
    });

    this.setState({ discipline: list });
  };
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => {
      dispatch(signUp(newUser));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
