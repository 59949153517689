import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import moment from "moment";
import {
  updateProject,
  createSavedItem,
  createProblemReport,
} from "../../Store/actions/projectActions";
import "./OpportunityDetails.css";
import AddToCalendar from "react-add-to-calendar";
import { Spinner } from "gestalt";
import SignInPop from "../Auth/SignInPop";
import { NavLink } from "react-router-dom";

class OpportunityDetails extends Component {
  componentDidMount() {
    window.$(".sidenav").sidenav();
    window.scrollTo(0, 0);
  }

  state = {
    showSignIn: false,
  };

  handleSubmit = (opportunity) => (e) => {
    e.preventDefault();

    this.props.createOfficialOpportunity(opportunity, this.props.id);

    //Send user to the homepage
    if (this.props.profile.owner) {
      this.props.history.push("/opportunityPage");
    } else {
      this.props.history.push("/search");
    }
  };

  render() {
    const { auth, savedItems, opportunity, profile, id } = this.props;

    if (savedItems && this.props.id in savedItems && !this.state.saved) {
      this.setState({ saved: true });
    }

    const trigger = (
      <a class="red waves-effect waves-light btn modal-trigger" href="#modal1">
        Report Program
      </a>
    );

    if (auth.uid && this.state.showSignIn) {
      this.setState({ showSignIn: false });
    }

    if (opportunity) {
      let event;
      if (opportunity.openDate || opportunity.dueDate) {
        event = {
          title:
            "Apply to: " +
            opportunity.organizationName +
            ": " +
            opportunity.programName,
          description: "Application website: " + opportunity.programUrl,
          startTime: opportunity.openDate
            ? moment(opportunity.openDate).calendar()
            : moment(opportunity.dueDate).calendar(),
          endTime: opportunity.dueDate
            ? moment(opportunity.dueDate).calendar()
            : moment(opportunity.openDate).calendar(),
        };
      }
      return (
        <div style={{ margin: 50 }}>
          {opportunity.logo ? (
                  <img
                    src={opportunity.logo}
                    style={{ maxWidth: 400, maxHeight: 100 }}
                    alt="new"
                    class="logo"
                  />
                ) : null}
          <div style={{ minHeight: 350 }}>
          
            <h4 class="teal-text">
              <strong> {opportunity.programName}</strong>
            </h4>
            <h5 class="green-text">{opportunity.organizationName}</h5>

<p/>
            {/* {opportunity.dueDate ? (
                  <h6>
                    <strong>Deadline: &nbsp;
                    {moment(opportunity.dueDate).calendar()}
                    </strong>
                  </h6>
                ) : null} */}
            <div class="row">
              <div class="col m8">
                <pre className="card-subtitle">
                  <div style={{ fontFamily: "times", paddingBottom: 30 }}>
                    {opportunity.programDescription}
                  </div>
                </pre>

                {opportunity.programCost !== "" && opportunity.programCost ? (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Program Cost: ${opportunity.programCost}</strong>
                  </div>
                ) : null}
                {opportunity.scholarships ? (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Scholarships Avaiable</strong>
                  </div>
                ) : null}
                {opportunity.paid !== "" && opportunity.paid ? (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Pays Students</strong>
                  </div>
                ) : null}
                {opportunity.locations.length > 1 ? (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>
                      Locations: {opportunity.locations.join(", ")}
                    </strong>
                  </div>
                ) : (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Location: {opportunity.locations}</strong>
                  </div>
                )}
                {opportunity.grades.length > 1 ? (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Grades: {opportunity.grades.join(", ")}</strong>
                  </div>
                ) : (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Grade: {opportunity.grades}</strong>
                  </div>
                )}
                {opportunity.type.length > 1 ? (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Types: {opportunity.type.join(", ")}</strong>
                  </div>
                ) : (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Type: {opportunity.type}</strong>
                  </div>
                )}
                {opportunity.rollingAdmissions ? (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong> Rolling Admissions </strong>
                  </div>
                ) : null}

                {opportunity.discipline.length > 1 ? (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>
                      Disciplines: {opportunity.discipline.join(", ")}
                    </strong>
                  </div>
                ) : (
                  <div
                    class="orange lighten-2 white-text"
                    style={{
                      borderRadius: 20,
                      padding: 10,
                      display: "inline-block",
                      margin: 5,
                    }}
                  >
                    <strong>Discipline: {opportunity.discipline}</strong>
                  </div>
                )}
              </div>

              <div class="col m3 s12 right">

                <br />
                <br />
                <br />

                {profile.reviewer || profile.owner || profile.contributor ? (
                  <div className="input-field">
                    <button className="btn grey" style={{ borderRadius: 50 }}>
                      <div>
                        <NavLink class="white-text" to={"/review/edit/" + id}>
                          Edit Program Details
                        </NavLink>
                      </div>
                    </button>
                    <br />
                    <br />
                    <br />
                  </div>
                ) : null}

                <button
                  className="btn"
                  style={{ borderRadius: 50 }}
                  onClick={() => {
                    if (!auth.uid) {
                      this.showSignIn();
                    }
                  }}
                >
                  {" "}
                  {!auth.uid ? "Visit Website" : ""}{" "}
                  {auth.uid ? (
                    <div>
                      <a
                        class="white-text"
                        target="_blank"
                        href={opportunity.programUrl}
                      >
                        {" "}
                        Visit Website
                      </a>
                      <i class="material-icons right">call_made</i>
                    </div>
                  ) : null}
                </button>
                <br />
                <br />
                <button
                  class="btn green"
                  type="submit"
                  name="action"
                  disabled={this.state.saved}
                  style={{ borderRadius: 50 }}
                  onClick={() => this.saveItem(opportunity)}
                >
                  {" "}
                  {this.state.saved ? "Saved" : "Save for later"}
                  <i class="material-icons right">save</i>
                </button>

                <br />
                <br />
                <br />
                {/* <h5>Application Dates: </h5>
                {opportunity.openDate ? (
                  <h6 style={{ textIndent: 10 }}>
                    <strong>Open Date:</strong>{" "}
                    {moment(opportunity.openDate).calendar()}
                  </h6>
                ) : null}
                {opportunity.dueDate ? (
                  <h6 style={{ textIndent: 10 }}>
                    <strong>Deadline: </strong>
                    {moment(opportunity.dueDate).calendar()}
                  </h6>
                ) : null}
                <AddToCalendar
                  event={event}
                  buttonLabel="Add Opportunity To Calendar"
                /> */}
              </div>
            </div>

            <div class="row">
              <div class="col m6" />
            </div>
            {/* {!this.state.reported ? (
                    <Modal
                      header="What is wrong with this program's listing?"
                      trigger={trigger}
                    >
                      <div class="input-field">
                        <select
                          required
                          validate
                          class="browser-default"
                          onChange={this.handleProblemSelected}
                        >
                          <option value="0" disabled selected>
                            select an option
                          </option>
                          <option value="dates">Wrong Dates</option>
                          <option value="wrong description">
                            Wrong Description
                          </option>
                          <option value="link does not work">
                            Link does not work
                          </option>
                          <option value="program does not exist">
                            Program no longer exists
                          </option>
                          <option value="wrong dates">Wrong grades</option>
                          <option value="other">other</option>
                        </select>
                      </div>

                      <br />

                      <div class="input-field col s12">
                        <textarea
                          id="problemReason"
                          class="materialize-textarea blue-text"
                          onChange={this.handleTextAreaChange}
                        />
                        <label for="problemReason">
                          Please add more details about the problem with this listing.<br />
                        </label>
                      </div>

                      <button onClick={this.sendReport} class="right large">
                        Send Report
                      </button>

                      {this.state.showReportError ? (
                        <div>
                          <span class="red-text center">
                            {" "}
                            Please fill out both fields
                          </span>
                        </div>
                      ) : null}

                      {this.state.reportedFailed ? (
                        <div class="toast red">
                          <div class="toast-header">
                            Failed to Create Report
                          </div>
                          <div class="toast-body">
                            Please press the send button to try again
                          </div>
                        </div>
                      ) : null}
                    </Modal>
                  ) : (
                    <div class="toast">
                      <div class="toast-header">Program Reported!</div>
                    </div>
                  ) } */}
          </div>
          {/* TODO Madey left off here trying to figure out how to get the overlay to display correctly fullscreen */}
          {this.state.showSignIn ? (
            <SignInPop cancelAction={this.cancelSignIn} />
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="container center">
          <Spinner show={true} />
        </div>
      );
    }
  }

  cancelSignIn = () => {
    this.setState({ showSignIn: false });
  };

  handleTextAreaChange = (e) => {
    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.value,
    });
  };

  sendReport = (e) => {
    if (this.state.problem && this.state.problemReason) {
      let report = {
        problem: this.state.problem,
        problemReason: this.state.problemReason,
        link: "https://scholarahead.com/review/edit/" + this.props.id,
      };
      this.props.createProblemReport(report, this.props.id, (reported) =>
        this.updateReported(reported)
      );
      this.setState({ showReportError: false });
    } else {
      e.preventDefault();
      this.setState({ showReportError: true });
    }
  };

  updateReported = (reported) => {
    this.setState({ reportedFailed: !reported });
    this.setState({ reported: reported });
  };

  handleProblemSelected = (e) => {
    this.setState({ problem: e.target.value });
  };

  saveItem = (opportunity) => {
    this.props.createSavedItem(opportunity, this.props.id, (saved) =>
      this.updateSavedState(saved)
    );
  };

  showSignIn = () => {
    this.setState({ showSignIn: true });
  };

  updateSavedState = (saved) => {
    this.setState({ saved: saved });
  };
}

const mapStateToProps = (state, ownProps) => {
  const savedItems = state.firestore.data.saved;
  const id = ownProps.match.params.id;
  const opportunities = state.firestore.data.opportunities;
  const opportunity = opportunities ? opportunities[id] : null;
  // TODO Madey we are actually showing the opportunities from the review list here not the official opportunity list
  // maybe this is not a problem since the data should be the same. Question, do you even need the official opportunity
  // list anymore now that you are using algolia for search?
  return {
    id: ownProps.match.params.id,
    opportunity: opportunity,
    savedItems: savedItems,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (opportunity, id) =>
      dispatch(updateProject(opportunity, id, false)),
    createSavedItem: (project, id, updateSavedState) =>
      dispatch(createSavedItem(project, id, updateSavedState)),
    createProblemReport: (item, id, updateReported) =>
      dispatch(createProblemReport(item, id, updateReported)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect((props) => {
    if (!props.auth.uid) {
      return [{ collection: "opportunities", doc: props.id }];
    } else {
      return [
        { collection: "saved", where: [["authorId", "==", props.auth.uid]] },
        { collection: "opportunities", doc: props.id },
      ];
    }
  })
)(OpportunityDetails);
