import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Box } from "gestalt";

import "gestalt/dist/gestalt.css";
import { Redirect } from "react-router-dom";
import OpportunityList from "./OpportunityList";

class SavedProjects extends Component {
  state = {
    location: "",
    discipline: [],
    grade: "",
    type: []
  };

  componentDidMount() {
    window.$(".sidenav").sidenav();
  }

  render() {
    const { auth, opportunities, profile } = this.props;

    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }
    if (profile) {
      let isOwner = profile.owner || profile.contributor || profile.reviewer;

      return (
        <div class="container center">
          <h4 class="green-text">Your Saved Opportunities</h4>
          <OpportunityList
            opportunities={opportunities}
            isOwner={isOwner}
          />{" "}
        </div>
      );
    }
    return;
  }
}

const mapStateToProps = state => {
  return {
    opportunities: state.firestore.ordered.saved,
    auth: state.firebase.auth,
    firebase: state.firebase,
    profile: state.firebase.profile
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "saved",
        where: ["authorId", "==", props.auth.uid]
      }
    ];
  })
)(SavedProjects);
