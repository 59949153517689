import firebase from "../../Config/fbConfig";

export const createProject = project => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;

    firestore
      .collection("opportunities")
      .add({
        ...project,
        authorFirstName: profile.firstName,
        authorId: authorId,
        createdAt: new Date()
      })
      .then(response => {
        firestore
          .collection("students")
          .add({
            authorId: authorId,
            projectId: response.id,
            studentList: [
              {
                name: "",
                phone: "",
                day: 0
              }
            ]
          })
          .then(() => {
            dispatch({ type: "CREATE_STUDENTS" });
          })
          .catch(error => {
            console.log(error);
            dispatch({ type: "CREATE_STUDENTS_ERROR", error });
          });
        dispatch({ type: "CREATE_PROJECT", project: project });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: "CREATE_PROJECT_ERROR", error });
      });
  };
};

export const createOpportunity = (opportunity, refreshPage, showErrorToast) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    if(profile && profile.firstName && authorId) {
      opportunity.authorFirstName = profile.firstName;
      opportunity.authorId = authorId
    }
    firestore
      .collection("review")
      .add({
        ...opportunity,
        createdAt: new Date()
      })
      .then(response => {
        refreshPage();
        dispatch({ type: "CREATE_PROJECT", project: opportunity });
      })
      .catch(error => {
        showErrorToast();
        console.log(error);
        dispatch({ type: "CREATE_PROJECT_ERROR", error });
      });
  };
};

export const createSavedItem = (item, id, updateSavedState) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;

    console.log("profile", profile)
    firestore
      .collection("saved")
      .doc(id)
      .set({
        ...item,
        authorId: authorId,
        createdAt: new Date()
      })
      .then(response => {
        updateSavedState(true)
        dispatch({ type: "CREATE_SAVED_ITEM", project: item });
      })
      .catch(error => {
        updateSavedState(false)
        console.log(error);
        dispatch({ type: "CREATE_SAVED_ITEM_ERROR", error });
      });
  };
};

export const createProblemReport = (item, id, updateReported) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    console.log('madey', item)
    console.log('madey', firestore)
    firestore
      .collection("reports")
      .add({
        ...item,
        id: id,
        createdAt: new Date()
      })
      .then(response => {
        console.log("madey reported")
        updateReported(true)
        dispatch({ type: "CREATE_REPORT", project: item });
      })
      .catch(error => {
        console.log("madey reported failed", error)

        updateReported(false)
        console.log(error);
        dispatch({ type: "CREATE_REPORT_ERROR", error });
      });
  };
};

export const updateProject = (
  project,
  projectId,
  isReview,
  refreshPage,
  showErrorToast
) => {
  return (dispatch, getState, { getFirestore }) => {
    var collection = isReview ? "review" : "opportunities";
    const firestore = getFirestore();
    firestore
      .collection(collection.toString())
      .doc(projectId)
      .update({
        ...project
      })
      .then(() => {
        console.log("successful update");
        refreshPage();
        dispatch({ type: "UPDATE_PROJECT", project: project });
      })
      .catch(error => {
        showErrorToast();
        dispatch({ type: "UPDATE_PROJECT_ERROR", error });
      });
  };
};

const updateProjectAction = (
  firestore,
  dispatch,
  project,
  projectId,
  refreshPage,
  showErrorToast
) => {
  firestore
    .collection("review")
    .doc(projectId)
    .update({
      ...project
    })
    .then(() => {
      console.log("successful update");
      refreshPage();
      dispatch({ type: "UPDATE_PROJECT", project: project });
    })
    .catch(error => {
      showErrorToast();
      dispatch({ type: "UPDATE_PROJECT_ERROR", error });
    });
};

export const createOfficialOpportunity = (
  project,
  projectId,
  refreshPage,
  showErrorToast
) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    if (project.dueDate) {
      let dueDateInUnix = Date.parse(project.dueDate);
      project.dueDateU = dueDateInUnix;
    }

    if (project.openDate) {
      let openDateInUnix = Date.parse(project.openDate);
      project.openDateU = openDateInUnix;
    }

    firestore
      .collection("opportunities")
      .doc(projectId)
      .set({
        ...project
      })
      .then(response => {
        project.reviewed = true;
        updateProjectAction(
          firestore,
          dispatch,
          project,
          projectId,
          refreshPage,
          showErrorToast
        );
        dispatch({ type: "CREATE_PROJECT", project: project });
      })
      .catch(error => {
        showErrorToast();
        console.log("error", error);
        dispatch({ type: "CREATE_PROJECT_ERROR", error });
      });
  };
};

export const deleteProject = (project, projectId, isReview) => {
  return (dispatch, getState, { getFirestore }) => {
    var collection = isReview ? "review" : "opportunities";
    const firestore = getFirestore();
    firestore
      .collection(collection.toString())
      .doc(projectId)
      .delete()
      .then(() => {
        console.log("successful delete");
        dispatch({ type: "DELETE_PROJECT", project: project });
      })
      .catch(error => {
        dispatch({ type: "DELETE_PROJECT_ERROR", error });
      });
  };
};

export const updateStudents = (studentsId, students) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("students")
      .doc(students.id)
      .update({
        ...students
      })
      .then(() => {
        dispatch({ type: "UPDATE_STUDENT", students: students.students });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: "UPDATE_STUDENTS_ERROR", error });
      });
  };
};

export const getEventsForDashboard = lastEvent => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const eventsRef = firestore.collection("review");

    try {
      let startAfter =
        lastEvent &&
        (await firestore
          .collection("review")
          .doc(lastEvent.id)
          .get());
      let query;

      lastEvent
        ? (query = eventsRef
            .where("programDescription", ">", "a")
            .orderBy("programDescription")
            .startAfter(startAfter)
            .limit(5))
        : (query = eventsRef
            .where("programDescription", ">", "a")
            .orderBy("programDescription")
            .limit(5));

      let querySnap = await query.get();
      if (querySnap.docs.length === 0) {
        //TODO Maybe show in the UI that we don't have any more events by dispatching some sort of event
        return;
      }
      let events = [];

      for (let i = 0; i < querySnap.docs.length; i++) {
        let evt = { ...querySnap.docs[i].data(), id: querySnap.docs[i].id };
        events.push(evt);
      }
      dispatch({ type: "FETCH_EVENTS", payload: { events } });
      return querySnap;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getEventsByLocation = (lastEvent, location) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const eventsRef = firestore.collection("review");

    try {
      let startAfter =
        lastEvent &&
        (await firestore
          .collection("review")
          .doc(lastEvent.id)
          .get());
      let query;

      lastEvent
        ? (query = eventsRef
            .where("location", "array-contains", location)
            .orderBy("programDescription")
            .startAfter(startAfter)
            .limit(5))
        : (query = eventsRef
            .where("programDescription", ">", "a")
            .orderBy("programDescription")
            .limit(5));

      let querySnap = await query.get();
      if (querySnap.docs.length === 0) {
        //TODO Maybe show in the UI that we don't have any more events by dispatching some sort of event
        return;
      }
      let events = [];

      for (let i = 0; i < querySnap.docs.length; i++) {
        let evt = { ...querySnap.docs[i].data(), id: querySnap.docs[i].id };
        events.push(evt);
      }
      dispatch({ type: "FETCH_EVENTS", payload: { events } });
      return querySnap;
    } catch (error) {
      console.log(error);
    }
  };
};
