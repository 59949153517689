import React from "react";
import { NavLink } from "react-router-dom";

const SignedOutLinks = () => {
  return (
    <ul className="right">
      <li>
        
            <a
              href={"http://" + "facebook.com/pg/scholarahead"}
              class="tooltipped btn-floating btn-small  teal-darken-3 hide-on-med-and-down"
              data-position="bottom"
              data-tooltip="Instagram"
            >
              <i class="fab fa-facebook" />
            </a>
          </li>
          <li>
            <a
              href={"http://" + "instagram.com/scholarahead"}
              class="tooltipped btn-floating btn-small teal-darken-3 hide-on-med-and-down"
              data-position="bottom"
              data-tooltip="Facebook"
            >
              <i class="fab fa-instagram" />
            </a>
          </li>
          <li>
            <a
              href={"http://" + "twitter.com/scholarahead"}
              class="tooltipped btn-floating btn-small teal-darken-3 hide-on-med-and-down"
              data-position="bottom"
              data-tooltip="Twitter"
            >
              <i class="fab fa-twitter" />
            </a>
          </li>
          <li>
        <NavLink class="teal-text" to="/signup">Sign Up</NavLink>
      </li>
      <li>
        <NavLink class="teal-text" to="/signin">Sign In</NavLink>
      </li>
      <li>
      <NavLink class="teal-text" to="/AddProgram">Add Program</NavLink>
      </li>
      <li>
      <a class="teal-text"href={"http://scholarahead.com/blog"}>Blog</a>
      </li>
    </ul>
  );
};

export default SignedOutLinks;
