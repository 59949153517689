import React, { Component } from "react";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div class="container">
        <h3 class="center">Privacy Policy</h3>

        <p>
          This Privacy Policy applies to{" "}
          <a href="https://scholarahead.com/">
            www.scholarahead.com
          </a>
          . This Privacy Policy describes how scholarahead.com collects
          and uses the personal information you provide. It also describes the
          choices available to you regarding the use of, your access to, and how
          to update and correct your personal information.
        </p>
        <p>
          scholarahead.com recognizes that your privacy is important. To
          demonstrate our firm commitment to your privacy and to explain the
          types of information we obtain about visitors to our website, how that
          information is obtained and used, and how visitors can restrict its
          use or disclosure, we have created the Privacy Policy below. The
          following discusses our information gathering and dissemination
          practices at scholarahead.com.
        </p>
        <div class="dividernil" />
        <h5>Information We Receive and How It Is Used</h5>
        <p>
          All personal information obtained by scholarahead.com about
          individual visitors to our website is information supplied voluntarily
          by that visitor. During registration, users are required to provide
          certain categories of information, such as name, address, email
          address, a password, grade, ethnicity, gpa. scholarahead.com
          encourages users to provide all fields of information to maximize the
          effectiveness of the opportunity and college search. We ask for
          permission when sharing information with marketing partners, which are
          reputable companies and organizations that provide products and
          services of interest to students and parents. Users may choose whether
          to opt-in or opt-out of sharing information with our marketing
          partners.
        </p>
        <p>
          <strong>
            scholarahead.com does not share personal information with
            third parties.
          </strong>
        </p>
        <p>
          Upon request scholarahead.com will provide you with
          information about whether we hold any of your personal information.
          Users may change personal profile information at
          scholarahead.com at any time by logging in to the website and
          entering the email address and password associated with their profile.
          After users have logged in, they will be directed to their opportunity
          Matches. In the tabbed navigation at the top of the page, users will
          find a link to "Profile". By clicking on that tab, users will be
          directed to a summary of their information, divided into sections. An
          "Edit This Section" option in the upper-right corner of each section
          allows users to modify and update their information. Users can also{" "}
          <a href="/about-us/contact-us/privacy-policy/">contact us here</a> in
          order to request access to submitted information. To protect the
          security and integrity of our users’ information, we will take
          necessary steps in verifying all new profile identities before
          providing access. It is the responsibility of the user to make all
          necessary changes in their profile and make sure their profiles are
          up-to-date.
        </p>
        <p>
          Users of scholarahead.com who wish to terminate their
          membership, or otherwise request to cease usage of their information
          at scholarahead.com may do so at any time by contacting us via
          our contact form by{" "}
          <a
            href="/about-us/contact-us/account-updates-and-questions/"
            title="Website Content"
            target="_top"
          >
            clicking here
          </a>
          . Please be sure to type "Remove" in the subject field and submit the
          email account associated with the membership you would like
          terminated. We will respond to your request within a reasonable
          timeframe. Information will be retained for as long as the account is
          active or requests our services. We will retain and use your
          information as necessary to comply with our legal obligations, resolve
          disputes, and enforce our agreements.
        </p>
        <p>
          scholarahead.com is committed to safeguarding users' privacy
          on our website. We follow generally accepted standards to protect the
          personal information submitted to us, both during transmission and
          once it is received. We require our employees to protect our users and
          visitors by keeping their profile information private, and expect our
          marketing partners to follow suit. Rest assured that your user profile
          will be protected when you visit scholarahead.com. There are
          security measures to protect against the loss, misuse and alteration
          of all user information under our control.
        </p>
        <p>
          Regardless of whether you wish to share your personal information, we
          reserve the right to share aggregated demographic data and related
          information with our business partners. Such aggregate information
          will not contain any personal information that can identify any
          individual user.
        </p>
        <h5 id="optinoptout">Information Sharing & Email Preferences</h5>
        <p>
          scholarahead.com understands the importance of protecting
          personally-identifiable information. We will not share, sell, rent or
          otherwise transfer any information without the user's permission, and
          then, only in accordance with the terms set forth in this Privacy
          Policy. Our website provides users the opportunity to
          unsubscribe/opt-out from receiving email messages from
          scholarahead.com and its marketing partners, or from having
          their personal information shared with our marketing partners. Users
          may change their email preferences by following the unsubscribe
          instructions contained in each of the email messages they receive.
          Please allow up to seven business days for these changes to take
          effect. If you no longer wish to be a registered user of
          scholarahead.com, you may have your profile removed by{" "}
          <a
            href="/about-us/contact-us/account-and-notification-settings/"
            title="Email Unsubscribe"
            target="_top"
          >
            clicking here
          </a>
          .
        </p>
        <p>
          scholarahead.com may periodically use contact information in
          order to send emails regarding updates at scholarahead.com
          site, such as opportunity opportunities and additional listings. The
          frequency of these messages will vary depending on the user's
          preference, including the types of opportunities and other activities
          in which a user has indicated an interest.
        </p>
        <p>
          In certain situations, scholarahead.com may be required to
          disclose personal data in response to lawful requests by public
          authorities. We may also disclose your personal information as
          required by law, such as to comply with a subpoena, or similar legal
          process. When we believe that disclosure is necessary to protect our
          rights, protect your safety or the safety of others, investigate
          fraud, or respond to a government request.
        </p>
        <p>
          We may provide your personal information to company provider services
          that help us with business-related activity; such as shipping your
          order or offering customer service. These companies are authorized to
          use your personal information only on a service-needs basis.
        </p>
        <h5>Collection of Passive Information</h5>
        <p>
          As is true of most websites, we gather certain information
          automatically. This information may include Internet protocol (IP)
          addresses, browser type, internet service provider (ISP),
          referring/exit pages, operating system, date/time stamp, and/or
          clickstream data.
        </p>
        <p>
          We, scholarahead.com, and our partners use cookies or similar
          technologies to analyze trends, administer the website, track users’
          movements around the website, and to gather demographic information
          about our user base as a whole. You can control the use of cookies at
          the individual browser level, but if you choose to disable cookies, it
          may limit your use of certain features or functions on our website or
          service.
        </p>
        <p>
          Some of our business partners, such as advertisers, use cookies on our
          site, but we have neither access to, nor control over, these cookies.
          This Privacy Policy covers the use of cookies by
          scholarahead.com only and does not cover the use of cookies by
          our business partners
        </p>
        <p>
          Our Web site includes Social Media Features, such as the Facebook Like
          button. These Features may collect your IP address, page visitation on
          the site, and may set a cookie to enable the Feature to function
          properly. Social Media Features are either hosted by a third party or
          hosted directly on our site. Your interactions with these Features are
          governed by the privacy policy of the company providing them.
        </p>
        <h5>Links to Other Websites</h5>
        <p>
          scholarahead.com contains text and banner links to other
          sites. scholarahead.com does not exercise control over the
          information, products, services or policies of third party companies
          accessible through our site. This Privacy Policy applies solely to the
          information collected by this site
        </p>
        <h5>Collection of Information from Children</h5>
        <p>
          scholarahead.com abides by federal law and the guidelines set
          up by the Federal Trade Commission regarding youth privacy. As such,
          scholarahead.com will not knowingly collect any
          personally-identifiable information from children under the age of 13,
          nor will children under the age of 13 be allowed to register with
          scholarahead.com.{" "}
        </p>
        <h5>Notification of Changes</h5>
        <p>
          If we are going to use your personally identifiable information in a
          manner different from that stated at the time of collection, we will
          notify you via email prior to the effective change. You may decide
          whether or not we can use your information in this manner. In
          addition, if we make any material changes to our Privacy Policy that
          do not affect user information already stored in our database, we will
          post a prominent notice on our website notifying users of the change.
          In cases where we post notice of changes, we may also email users who
          have opted to receive communications from us, notifying them of the
          changes to our privacy practices.
        </p>
        <p>
          In the event that scholarahead.com sells, signs or transfers
          some or all of its business to a successor or acquirer,
          scholarahead.com may sell, assign or transfer all of your
          information, regardless of your opt status, to such successor or
          acquirer.
        </p>
        <p>Last Revised in June 2019</p>
      </div>
    );
  }
}

export default PrivacyPolicy;
