import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Box, Spinner } from "gestalt";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { createOpportunity } from "../../Store/actions/projectActions";

import "gestalt/dist/gestalt.css";

class EditProgram extends Component {
  state = {
    firstTime: true
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      ...nextProps.program
    };
  }
  render() {
    const { auth, profile, program } = this.props;
    //TODO if not owner or editor then do not let them access this page

    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }
    if (!profile.owner) return <Redirect to="/search" />;
    if (program) {
      return (
        <div>
          <Box color="white" padding={10} display="flex" direction="column">
            <h5 class="center">Edit Program: </h5>
            <div class="card darken-1">
              <form>
                <div class="input-field col s6">
                  <span>Required: Organization Name </span>
                  <input
                    id="organizationName"
                    type="text"
                    class="active"
                    placeholder=""
                    required
                    value={program.organizationName}
                    onChange={this.handleTextAreaChange}
                  />
                </div>
                <br />
                <br />
                <br />

                <div class="input-field col s6">
                  <span>Required: Program Name </span>
                  <input
                    id="programName"
                    type="text"
                    class="validate"
                    required
                    onChange={this.handleTextAreaChange}
                    value={program.programName}
                  />
                </div>

                <br />
                <br />
                <br />

                <div class="input-field col s12">
                  <span>Required: Program Description </span>
                  <textarea
                    id="programDescription"
                    class="materialize-textarea"
                    required
                    onChange={this.handleTextAreaChange}
                    value={program.programDescription}
                  />
                </div>

                <br />
                <br />
                <br />

                <div class="input-field col s6">
                  <span>
                    Please enter the direct website url for this program ex;
                    https://example.com.
                  </span>
                  <input
                    type="url"
                    name="url"
                    id="programUrl"
                    pattern="https://.*"
                    size="30"
                    required
                    onChange={this.handleTextAreaChange}
                    value={program.programUrl}
                  />
                </div>

                <br />
                <br />
                <br />

                <div class="input-field col s6">
                  <span>
                    Program Cost Program Cost. If it is a range please add the
                    range. Example: $1,000 - $2,000
                  </span>
                  <input
                    id="programCost"
                    type="text"
                    class="validate"
                    onChange={this.handleTextAreaChange}
                    value={program.programCost}
                  />
                </div>

                <br />
                <br />
                <br />

                <p>
                  <label>
                    <input
                      id="scholarships"
                      type="checkbox"
                      onChange={this.handleCheckBoxChange}
                    />
                    <span>
                      Does The Program Offer Scholarships or financial aid?
                    </span>
                  </label>
                </p>

                <br />
                <br />
                <br />

                <div>
                  <span>Open Date</span>
                  <input
                    id="openDate"
                    type="date"
                    class="datepicker"
                    onChange={this.handleTextAreaChange}
                    value={program.openDate}
                  />
                </div>

                <br />
                <br />
                <br />

                <div>
                  <span>Due Date</span>
                  <input
                    id="dueDate"
                    type="date"
                    class="datepicker"
                    onChange={this.handleTextAreaChange}
                    value={program.dueDate}
                  />
                </div>

                <br />
                <br />
                <br />

                <p>
                  <label>
                    <input
                      id="rollingAdmissions"
                      type="checkbox"
                      onChange={this.handleCheckBoxChange}
                    />
                    <span>Does The Program Have Rolling Admissions?</span>
                  </label>
                </p>

                <br />
                <br />
                <br />

                <div class="row">
                  <h5 class="blue-text center">
                    Please enter the city and state if the program has only one
                    location
                  </h5>
                  <div class="input-field col s6">
                    <input
                      id="programCity"
                      type="text"
                      required
                      onChange={this.handleTextAreaChange}
                      value={program.programCity}
                    />
                  </div>

                  <div class="input-field col s6">
                    <input
                      id="programState"
                      type="text"
                      required
                      onChange={this.handleTextAreaChange}
                      value={program.programState}
                    />
                  </div>
                </div>

                <div class="input-field">
                  <span>
                    Required: Choose locations for this program. Select where
                    this program is hosted. It could be more than one city.
                  </span>

                  <select
                    class="browser-default"
                    onChange={this.handleLocationChanged}
                  >
                    <option value="0" disabled selected />
                    <option value="New York">International</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                </div>

                {this.state.locations.map(location => {
                  return (
                    <div>
                      <a
                        href="#"
                        class="btn  blue lighten-2"
                        onClick={() => this.handleRemoveLocation(location)}
                      >
                        <span>{location}</span>
                        <i class="material-icons">clear</i>
                      </a>{" "}
                    </div>
                  );
                })}

                <div class="input-field">
                  <span>
                    Required: Choose grades for this program. It could be
                    multiple grades. Please select all that are true.
                  </span>
                  <select
                    required
                    validate
                    class="browser-default"
                    onChange={this.handleGradeChanged}
                  >
                    ><option value="0" disabled selected />
                    <option value="k">Kindergarden</option>
                    <option value="1">First Grade (1st)</option>
                    <option value="2">Second Grade (2nd)</option>
                    <option value="3">Third Grade (3rd)</option>
                    <option value="4">Fourth Grade (4th)</option>
                    <option value="5">Fifth Grade (5th)</option>
                    <option value="6">Sixth Grade (6th)</option>
                    <option value="7">Seventh Grade (7th)</option>
                    <option value="8">Eight Grade (8th)</option>
                    <option value="9">High School Freshman (9th)</option>
                    <option value="10">High School Sophomore (10th)</option>
                    <option value="11">
                      High School Freshman Junior (11th)
                    </option>
                    <option value="12">
                      High School Freshman Senior (12th)
                    </option>
                  </select>
                </div>

                {this.state.grades.map(discipline => {
                  return (
                    <div>
                      <a
                        href="#"
                        class="btn  blue lighten-2"
                        onClick={() => this.handleRemoveGrade(discipline)}
                      >
                        <span>{discipline}</span>
                        <i class="material-icons">clear</i>
                      </a>
                    </div>
                  );
                })}

                <div class="input-field">
                  <span>
                    Required: Choose disciplines for this program. It could be
                    multiple disciplines.
                  </span>
                  <select
                    class="browser-default"
                    onChange={this.handleDisciplineChanged}
                  >
                    <option value="0" disabled selected />
                    <option value="accounting">accounting</option>
                    <option value="science">Science</option>
                    <option value="math">Math</option>
                    <option value="creative arts">Creative Arts</option>
                    <option value="technology">Technology</option>
                    <option value="dance">Dance</option>
                    <option value="sports">Sports</option>
                    <option value="business">Business</option>
                    <option value="writing">Writing</option>
                    <option value="education">Education</option>
                    <option value="english">English</option>
                    <option value="finance">Finance</option>
                    <option value="sat prep">SAT Prep</option>
                    <option value="college prep">College Prep</option>
                    <option value="law">Law</option>
                    <option value="international affairs">
                      International Affairs
                    </option>
                    <option value="self esteem">Self Esteem</option>
                    <option value="outdoors">Outdoors</option>
                    <option value="high school prep">High school prep</option>
                    <option value="leadership development">
                      Leadership Development
                    </option>
                    <option value="politics">Politics</option>
                    <option value="social impact">Social Impact</option>
                    <option value="research">Research</option>
                    <option value="music">Music</option>
                    <option value="engineering">Engineering</option>
                    <option value="Volunteer">Volunteer</option>
                    <option value="other">other</option>
                  </select>
                  <p />

                  {this.state.discipline.map(discipline => {
                    return (
                      <div>
                        <a
                          href="#"
                          class="btn  blue lighten-2"
                          onClick={() => this.removeDiscipline(discipline)}
                        >
                          <span>{discipline}</span>
                          <i class="material-icons">clear</i>
                        </a>
                      </div>
                    );
                  })}
                </div>

                <div class="input-field">
                  <span>
                    Required: Choose type for this program. It could be multiple
                    types.
                  </span>
                  <select
                    class="browser-default"
                    onChange={this.handleTypeChanged}
                  >
                    ><option value="0" disabled selected />
                    <option value="after school">After School</option>
                    <option value="summer">Summer Break</option>
                    <option value="winter">Winter Break</option>
                    <option value="weekend">Weekend</option>
                    <option value="year round">Year Round</option>
                  </select>
                  <p />
                  {this.state.type.map(programType => {
                    return (
                      <div>
                        <a
                          href="#"
                          class="btn  blue lighten-2"
                          onClick={() => this.removeType(programType)}
                        >
                          <span>{programType}</span>
                          <i class="material-icons">clear</i>
                        </a>
                      </div>
                    );
                  })}
                </div>
                <div class="right">
                  <button
                    class="btn waves-effect waves-light"
                    type="submit"
                    name="action"
                    onClick={this.handleSubmit}
                  >
                    Submit
                    <i class="material-icons right">send</i>
                  </button>
                </div>
              </form>
              <div className="red-text center">
                {this.state.showError ? (
                  <p>Please fill out all required fields</p>
                ) : null}
              </div>
            </div>
          </Box>
        </div>
      );
    } else {
      return (
        <div className="container center">
          <Spinner show={true} />
        </div>
      );
    }
  }

  handleCheckBoxChange = e => {
    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.checked
    });
  };

  handleTextAreaChange = e => {
    if (e.target.checked) {
    }

    const updateField = e.target.id;
    this.setState({
      [updateField]: e.target.value
    });
  };

  handleSubmit = e => {
    if (
      this.state.programName !== "" &&
      this.state.organizationName !== "" &&
      this.state.programDescription !== "" &&
      this.state.programUrl !== "" &&
      this.state.grades.length > 0 &&
      this.state.discipline.length > 0 &&
      this.state.locations.length > 0 &&
      this.state.type.length > 0
    ) {
      const program = {
        ...this.state
      };
      this.setState({ showError: false });
      this.props.createOpportunity(program);
    } else {
      e.preventDefault();
      this.setState({ showError: true });
    }
    //Send user to the homepage
    // this.props.history.push("/signin");
  };

  removeDiscipline = programDiscipline => {
    let list = this.state.discipline.filter(type => {
      return type !== programDiscipline;
    });

    this.setState({ discipline: list });
  };

  removeType = programType => {
    let list = this.state.type.filter(type => {
      return type !== programType;
    });

    this.setState({ type: list });
  };

  handleLocationChanged = e => {
    const locationList = this.state.locations;
    locationList.push(e.target.value);

    this.setState({ locations: locationList });
  };

  handleRemoveLocation = location => {
    let list = this.state.locations.filter(type => {
      return type !== location;
    });
    this.setState({ locations: list });
  };

  handleGradeChanged = e => {
    const gradeList = this.state.grades;
    gradeList.push(e.target.value);

    this.setState({ grades: gradeList });
  };

  handleRemoveGrade = grade => {
    let list = this.state.grades.filter(type => {
      return type !== grade;
    });
    this.setState({ grades: list });
  };

  handleDisciplineChanged = e => {
    const disciplineList = this.state.discipline;
    disciplineList.push(e.target.value);
    this.setState({ discipline: disciplineList });
  };

  handleTypeChanged = e => {
    const typeList = this.state.type;
    if (!typeList.includes(e.target.value)) {
      typeList.push(e.target.value);
    }
    this.setState({ type: typeList });
  };

  onSubmitClicked = e => {
    createOpportunity(this.state, this.props.id);
  };
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const opportunities = state.firestore.data.opportunities;
  const opportunity = opportunities ? opportunities[id] : null;
  return {
    id: ownProps.match.params.id,
    program: opportunity,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOpportunity: (project, id) =>
      dispatch(createOpportunity(project, id, false))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "opportunities" }])
)(EditProgram);
