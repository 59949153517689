import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class TermsOfUse extends Component {
  render() {
    return (
        <div class="container">
             <h3 class="center">Terms of Use</h3>
          <p>
            scholarahead.com reserves the right, at its sole discretion, to
            change, modify, add, remove or terminate any portion of this
            Agreement, in whole or in part, at any time, without prior notice.
            As a user of the Site, it is your responsibility to check these
            Terms of Use for changes periodically. Your continued use of the
            Site, following the posting of any such changes will mean that you
            unconditionally accept and agree to the aforementioned changes. For
            as long as you comply with these Terms of Use, scholarahead.com
            grants you a non-exclusive, personal, non-transferable, limited
            privilege to enter and use the Site.
          </p>

          <h5>Accounts, Passwords and Security</h5>

          <p>
            Certain features and/or services offered on or through the Site may
            require you to register, including setting up a scholarahead.com
            user account. You are entirely responsible for maintaining the
            confidentiality of your account information, including your
            password, and for any and all activity that occurs under your
            account. By using the Site you agree to notify scholarahead.com
            immediately of any unauthorized use of your account or password, or
            any other breach of security. You may be held liable for losses
            incurred by scholarahead.com or any other user of or visitor to the
            Site due to someone else using your scholarahead.com password or
            account.
          </p>

          <p>
            You may not use anyone else’s scholarahead.com password or account
            at any time without the permission and consent of the holder of that
            scholarahead.com password or account. scholarahead.com cannot and
            will not be liable for any loss or damage arising from your failure
            to comply with these obligations.
          </p>

          <h5>Collection of Information from Children</h5>

          <p>
            scholarahead.com abides by federal law and the guidelines set up by
            the Federal Trade Commission regarding youth privacy. As such,
            scholarahead.com will not knowingly collect any
            personally-identifiable information from children under the age of
            13, nor will children under the age of 13 be allowed to register
            with scholarahead.com.
          </p>

          <p>
            This Web site is not intended for children under the age of 13.
            scholarahead.com will not knowingly register or otherwise collect
            Personal Information from persons who identify themselves as being
            under the age of 13 during the registration process, without prior
            consent of such child’s parent or guardian.
          </p>

          <h5>Content</h5>

          <p>
            All trademarks, computer code, text, logos, graphics, user
            interfaces, visual interfaces, photographs and artwork
            (collectively, "Content"), including but not limited to: the
            structure, design, selection, coordination, expression, and
            arrangement of all Content contained on the Site is owned,
            controlled or licensed by or to scholarahead.com, and is protected
            by copyright, patent laws, trademark laws, and many other
            intellectual property rights and unfair competition laws.
          </p>

          <p>
            No part of the Site nor any Content may be copied, reproduced in any
            way, republished, uploaded, posted, publicly displayed, encoded,
            translated, transmitted or distributed in any way (including
            "mirroring") to any other computer, server, Web site or other medium
            for any form of publication or distribution or for any commercial
            enterprise whatsoever, without scholarahead.com’s express prior
            written permission except as expressly provided in these Terms of
            Use.
          </p>

          <p>
            You may use any information on scholarahead.com services made
            available by scholarahead.com for downloading from the Site,
            provided that you:
          </p>
          <ul>
            <li>
              Do not remove any proprietary notice language in any and/or all
              copies of such documents;
            </li>
            <li>
              Use such information only for your own personal, non-commercial
              purpose and do not copy or in any way display such information on
              any networked computer or broadcast it in any media or in any
              form;
            </li>
            <li>Make no modifications to any such information; and,</li>
            <li>
              Do not make any additional or supplementary representations or
              warranties relating to the content of the Site.
            </li>
          </ul>

          <p>
            <a
              href="/about-us/copyrights-and-permissions/"
              title="Copyrights & Permissions"
              target="_top"
            >
              Please click here for more information on scholarahead.com’s
              Copyrights and Permissions.
            </a>
          </p>

          <h5>Disclaimers</h5>

          <p>
            scholarahead.com does not guarantee that the Site or any Content,
            service or feature will be error-free or uninterrupted; or that any
            defects will be corrected, or that your use of the Site will provide
            specific results. The Site and its content are delivered strictly on
            an "as-is" and "as-available" basis. All information provided on the
            Site is subject to change without notice. scholarahead.com cannot
            ensure that any of the files or any other data you download from the
            Site will be free of viruses or other contamination, or any other
            destructive features. scholarahead.com disclaims all warranties,
            express or implied, including any warranties of accuracy,
            non-infringement, merchantability and fitness for a particular
            purpose. scholarahead.com disclaims any and all liability for the
            acts, omissions and the conduct of any third parties in connection
            with or related to your use of the Site and/or any scholarahead.com
            services. You are completely responsible for your use of the Site
            and any linked sites therein. Your sole remedy against
            scholarahead.com for dissatisfaction with the site or any content is
            to stop using the site or any such content at once. This limitation
            of relief is a part of the bargain between the parties.
          </p>

          <p>
            The above disclaimer applies to any damages, liability or other
            perceived injuries caused by any kind of failure of performance,
            error, omission, interruption, deletion, defect, delay in operation
            or transmission, computer virus, communication line failure, theft
            or destruction of or unauthorized access to, alteration of, or use;
            whether for breach of contract, tort, negligence or any other cause
            of action, similar or otherwise.
          </p>

          <p>
            scholarahead.com reserves the right to take any of the following
            actions, at any time it deems appropriate, without notice: (1) to
            modify, suspend or terminate operation of or access to the Site, or
            any portion of the Site, for any reason it deems appropriate; (2) to
            modify or change the Site, or any portion of the Site, and any
            applicable policies or terms; (3) to interrupt the operation of the
            Site, or any portion of the Site, as necessary to perform routine or
            non-routine maintenance, error correction, or other changes; and (4)
            suspend or cancel any registered account for any reason it deems
            appropriate, including without limitation any violation of these
            Terms of Use.
          </p>

          <h5>Feedback and Information</h5>

          <p>
            Any feedback or communication you provide you provide at this side
            shall be deemed as non-confidential. scholarahead.com is free to use
            such information on an unrestricted basis.
          </p>

          <p>
            By posting messages, uploading files, inputting data, transmitting
            email or engaging in any form of communication (individually or
            collectively “Communications”) to or through scholarahead.com Web
            site, you hereby grant to scholarahead.com a perpetual, worldwide,
            irrevocable, unrestricted, non-exclusive, royalty-free license to
            use, copy, license, sublicense, adapt, distribute, display, publicly
            perform, reproduce, transmit, modify, edit and otherwise exploit
            such Communications throughout the world, in all media now known or
            hereafter developed. You hereby waive all rights to any claim
            against scholarahead.com for any alleged or actual infringements of
            any propriety rights, rights to privacy and publicity, moral rights
            and rights of attribution in connection with such Communications.
          </p>

          <p>
            You will be responsible for your own Communications and the
            consequences of posting or otherwise transmitting those
            Communications. scholarahead.com does not represent or guarantee the
            truthfulness, accuracy or reliability of any material posted or
            otherwise transmitted by scholarahead.com Web site users or endorses
            any opinions expressed by such users. scholarahead.com reserves the
            right to expel users and prevent their further access to the
            scholarahead.com Web site for violating this Agreement or violating
            the law. We maintain the right, but not the obligation, to remove
            Communications that are abusive, illegal, disruptive or otherwise
            problematic.
          </p>

          <h5>Governing Law: Dispute Resolution</h5>

          <p>
            You agree that all matters relating to access to or use of the Site,
            including all disputes, will be governed by the laws of the United
            States and by the laws of the State of Illinois without regard to
            its conflicts of laws provisions. You agree to the personal
            jurisdiction by and venue in the state and federal courts in Cook
            County, Illinois, and you waive any objection to such jurisdiction
            or venue. Any claim under these Terms of Use must be brought within
            one (1) year after the cause of action arises, or such claim or
            cause of action is barred. No recovery may be sought or received for
            damages other than out-of-pocket expenses, except that the
            prevailing party will be entitled to costs and attorneys’ fees. In
            the event that any controversy or dispute between scholarahead.com
            and you arising out of or in connection with your use of the Site,
            the parties shall attempt, promptly and in good faith, to resolve
            any such dispute. If we are unable to resolve any such dispute
            within a reasonable time (not to exceed thirty (30) days), then
            either party may submit such controversy or dispute to mediation. If
            the dispute cannot be resolved through mediation, then the parties
            shall be free to pursue any right or remedy available to them under
            applicable law.
          </p>

          <h5>Indemnity</h5>

          <p>
            You agree to indemnify and hold scholarahead.com, its officers,
            directors, shareholders, predecessors, successors in interest,
            employees, agents, subsidiaries and affiliates, harmless from any
            demands, loss, liability, claims or expenses (including attorneys’
            fees), made against scholarahead.com by any third party due to or
            arising out of or in connection with your use of the Site.
          </p>

          <h5>Links to Other Sites</h5>

          <p>
            This Site may contain links to various other, independent,
            third-party Web sites ("Linked Sites"). These Linked Sites are
            provided solely as a convenience to our visitors and said Linked
            Sites are not under scholarahead.com’s control. scholarahead.com is
            not responsible for, nor does it endorse the content of any such
            Linked Sites, including any information or materials contained on or
            within such Linked Sites. scholarahead.com does not assume any
            responsibility or liability for the actions, product, content and/or
            information of these and other third parties and/or their web sites.
            It is your responsibility to call judgement regarding your
            interaction with the aforementioned Linked Sites.
          </p>

          <h5>Linking to scholarahead.com</h5>

          <p>
            You are welcome to{" "}
            <a href="/about-us/linking/" title="Linking" target="_top">
              link
            </a>{" "}
            to any page on scholarahead.com website. However, you may not
            include content from scholarahead.com on your site without express
            permission from scholarahead.com. All of our content has been
            copyrighted, thus protected by governing law and requiring the
            above-mentioned written consent for use by third parties.
          </p>

          <p>
            Any scholarahead.com page that you link to must open in its unique
            url; you may not "frame" our content or in any way make the content
            appear to be a part of your or any other site. If you are interested
            in partnership opportunities, such as a scholarahead.com powered
            "drop-in" program search for your site, please{" "}
            <a
              href="/about-us/contact-us/business-development-marketing-and-advertising/"
              title="Business Development"
              target="_top"
            >
              contact our business development department.
            </a>
          </p>

          <p>
            scholarahead.com’s logos and/or trademarks can be used for linking
            to us, provided you use the code we have provided on our
            page for this very purpose. Any other use of our logo is forbidden
            without our express permission. Requests for permission to use
            scholarahead.com content, trademarks, or logos should be submitted
            via email using our{" "}
            <a
              href="/about-us/contact-us/opportunity-search-questions/"
              title="Website Content"
              target="_top"
            >
              contact form
            </a>
            . scholarahead.com reserves the right to refuse permission at its
            sole discretion.
          </p>

          <p>
            scholarahead.com reserves the right to determine whether a link to
            its site is inappropriate in any way and reserves the right to
            require you, for any reason, to discontinue all or any links from
            your site or a site hosted by you. Should you receive such a
            request, you must immediately remove the specified links.
          </p>

          <h5>Limitation of Liability</h5>

          <p>
            In no event, where prohibited by law, will scholarahead.com be
            liable to you for any indirect, consequential, exemplary, incidental
            or punitive damages, including lost profits, even if
            scholarahead.com has been advised of the possibility of such
            damages.
          </p>

          <p>
            If, notwithstanding the other provisions of these Terms of Use,
            scholarahead.com is found to be liable to you for any kind of damage
            or loss which arises from or is in any way connected with your use
            of the Site or any Content, in no event shall scholarahead.com’s
            liability ever exceed the greater of
          </p>

          <ul>
            <li>
              The total of any fees with respect to any service or feature of or
              on the Site paid in the three months prior to the date of the
              initial claim made against scholarahead.com
            </li>
            <li>$250 US currency</li>
          </ul>

          <p>
            Some jurisdictions do not allow limitations of liability, so the
            foregoing limitation may not apply to you.
          </p>

          <h5>Miscellaneous</h5>

          <p>
            If any of the provisions of these Terms of Use are held by a court
            or other tribunal of competent jurisdiction to be void or
            unenforceable, such provisions shall be limited or eliminated to the
            minimum extent necessary and replaced with a valid provision that
            best embodies the intent of these Terms of Use, so that these Terms
            of Use shall remain in full force and effect. These Terms of Use
            constitute the entire agreement between you and scholarahead.com
            with regard to your use of the Site, and any and all other written
            or oral agreements or understandings previously existing between you
            and scholarahead.com with respect to such use are hereby superseded
            and cancelled. scholarahead.com will not accept any counter-offers
            to these Terms of Use, and all such offers are hereby categorically
            rejected. scholarahead.com’s failure to insist on or enforce strict
            performance of these Terms of Use shall not be construed as a waiver
            by scholarahead.com of any provision or any right it has to enforce
            these Terms of Use, nor shall any course of conduct between
            scholarahead.com and you or any other party be deemed to modify any
            provision of these Terms of Use. These Terms of Use shall not be
            interpreted or construed to confer any rights or remedies on any
            third parties.
          </p>

          <h5>Privacy Policy</h5>

          <p>
          <NavLink to="/PrivacyPolicy">To view scholarahead.com’s Privacy Policy, click here</NavLink>
            scholarahead.com’s Privacy Policy applies to use of this Site, and
            the terms are made a part of these Terms of Use by this reference.
            Additionally, by using the Site, you agree that Internet
            transmissions are never completely private or secure and that
            scholarahead.com cannot and will not be held accountable for the
            aforementioned potential lack of Internet transmission security. You
            understand and agree that any message or information you send to the
            Site may be read or intercepted by others, even if there is a
            special notice that a particular transmission is via a secure
            server.
          </p>

          <h5>Purchases: Terms and Conditions</h5>

          <p>
            Additional terms and conditions may apply to purchases of goods or
            services and to specific sections or features of the Site, including
            all contests, promotions or other similar features, all of which
            terms are made a part of these Terms of Use by this very reference.
            You agree to abide by such other terms and conditions, including
            where applicable representing that you are of the required legal age
            to use or participate in said service or feature. If there is any
            conflict between these Terms of Use and the terms posted for, or
            applicable to, a specific portion of the Site or for any service
            offered on or through the Site, the latter terms shall have control
            with respect to your use of that portion of the Site or that
            specific service.
          </p>

          <p>
            scholarahead.com may make changes to any services or any products
            offered on the Site, or to the applicable prices for any services or
            any products, at any time, without notice. The materials on the Site
            with respect to products and services may be outdated, and
            scholarahead.com makes no commitment to update the materials on the
            Site with respect to the aforementioned products and services.
          </p>

          <p>
            scholarahead.com’s obligations, if any, with regard to its products
            and services are governed solely by the agreements pursuant to which
            they are provided, and nothing on this Site should be construed to
            alter such agreements.
          </p>

          <p>
            Each of these policies may be changed from time to time and are
            effective immediately upon posting such changes on the Site.
          </p>

          <h5>Submit an opportunity</h5>

          <p>
            All information submitted to scholarahead.com is at the risk of the
            opportunity provider. scholarahead.com is not responsible for the
            accuracy of the information provided nor for any other aspect such
            as timeliness or response generated. All response to a opportunity
            generated as a result of inclusion in scholarahead.com database
            will be the sole responsibility of the provider of the opportunity.
            scholarahead.com reserves the right to do any of the following, at
            any time it deems appropriate, without notice: (1) to modify,
            suspend or terminate any opportunity listing or access to
            scholarahead.com, or any portion of scholarahead.com, for any reason
            it deems appropriate; (2) to modify, change or terminate any
            opportunity listing within scholarahead.com opportunity
            database, along with any policies or terms applicable to said
            opportunity listing.
          </p>

          <h5>Use of the Site</h5>

          <p>
            You are not, under any circumstance, to use any "deep-link,"
            "page-scrape," "robot," "spider," or any other such automatic
            device, program, algorithm or methodology, or any similar manual or
            automated process, to copy, access or monitor or otherwise acquire
            any portion of the Site or any Content therein, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Site or any Content therein, to obtain or attempt to obtain
            any materials, documents or any other such information through any
            means not purposely made available through the Site.
            scholarahead.com reserves the right to bar any such activity.
          </p>

          <p>
            You may not probe, scan, or test the vulnerability of the Site or
            any network that is connected to the Site, nor may you breach the
            security or authentication measures that are in place on the Site or
            any network connected to the Site. You may not reverse look-up,
            trace or seek to trace any information on any other user of and/or
            visitor to the Site, or any other customer of scholarahead.com,
            including any scholarahead.com account not owned by you, to its
            source, or exploit the Site or any service or information made
            available or offered by or through the Site, in any way where the
            purpose is to reveal any information, including but not limited to
            personal identification or information, other than your own
            information, as provided for by the Site.{" "}
          </p>

          <p>
            You may not attempt to gain unauthorized access to any portion nor
            any feature of the Site, nor may you attempt to view or access any
            other systems or networks connected to the Site nor may you attempt
            to gain access to any scholarahead.com server, nor to any of the
            services offered on or through the Site, by hacking, password
            "mining" or any other illegitimate means.{" "}
          </p>

          <p>
            By using the Site, you agree that you will not take any action that
            imposes an unreasonable or disproportionately large load on the
            servers or any part of the infrastructure of the Site or
            scholarahead.com’s systems or networks, or any other systems or
            networks connected to the Site or to scholarahead.com.{" "}
          </p>

          <p>
            You agree not to use any device, routine or software to interfere or
            attempt to interfere with the proper and efficient working of the
            Site or any transaction being conducted on the Site, or with any
            person’s use of the Site.{" "}
          </p>

          <p>
            You may not use the Site or any Content therein for any purpose that
            is unlawful, fraudulent or in any way prohibited by these Terms of
            Use, or to solicit the performance of any illegal activity or other
            activity which infringes the rights of scholarahead.com or others.{" "}
          </p>

          <p>
            You may not forge headers, manipulate, or otherwise disguise
            identifiers in order to hide the origin of any message or
            transmittal you send to scholarahead.com on or through the Site or
            any service offered on or through the Site. You may not pretend that
            you are, or that you represent, someone else, or impersonate any
            other individual or entity.{" "}
          </p>

          <p>
            You represent and warrant that any information you provide to
            scholarahead.com via the Site, including without limitation at the
            time you register an account with scholarahead.com, will be true,
            accurate, current and complete, and no such information will be
            provided under false pretenses.
          </p>

          <h5>Violation of Terms of Use</h5>

          <p>
            scholarahead.com reserves the right to investigate and take legal
            action against any illegal and/or unauthorized use of the Site.
            scholarahead.com’s decision not to pursue legal action for any
            violation of these Terms of Use shall not be construed as a waiver
            of any provision of the Terms of Use or scholarahead.com’s legal
            rights. scholarahead.com may prohibit you from using or accessing
            the Site, in whole or in part, for any or no reason, at any time, in
            its sole discretion, without notifying you. scholarahead.com may
            disclose any information we have about you (including your identity)
            if we have determined that such disclosure is necessary in
            connection with any investigation or complaint regarding your use of
            the Site, or to identify, contact or bring legal action against
            someone who may be causing injury to or interference with (either
            intentionally or unintentionally) scholarahead.com’s rights or
            property, or the rights or property of visitors to or users of the
            Site, including scholarahead.com’s customers. scholarahead.com
            reserves the right at all times to disclose any information that
            scholarahead.com deems necessary to comply with any applicable law,
            regulation, legal process or governmental request. scholarahead.com
            also may disclose your information if and when it has been
            determined that applicable law requires or permits such disclosure,
            including exchanging information with other companies and
            organizations for fraud protection purposes.
          </p>

          <p>
            You acknowledge and agree that scholarahead.com may preserve
            transmittal or communication by you with scholarahead.com through
            the Site or any service offered on or through the Site, and may also
            disclose such data if required to do so by law or scholarahead.com
            determines that such preservation or disclosure is reasonably
            necessary to:{" "}
          </p>

          <ul>
            <li>Comply with legal process</li>
            <li>Enforce these Terms of Use</li>
            <li>
              Respond to claims that any such data violates the rights of others
            </li>
            <li>
              Protect the rights, property or personal safety of
              scholarahead.com, its employees, users of or visitors to the Site,
              and the public.
            </li>
          </ul>

          <p>
            If scholarahead.com takes any legal action against you as a result
            of your violation of these Terms of Use, scholarahead.com will be
            entitled to recover from you, and you will agree to pay, all
            reasonable attorneys’ fees and costs of any such action, in addition
            to any other relief granted to scholarahead.com. You agree that
            scholarahead.com will not be liable to you or to any third party for
            termination of your access to the Site as a result of any violation
            of these Terms of Use.
          </p>

          <p>
            This Agreement and our Privacy Policy contain the entire agreement
            between you and scholarahead.com with respect to this Site. It
            supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and
            scholarahead.com with respect to the Site. Any rights not expressly
            granted herein are reserved.
          </p>

          <h5>Void Where Prohibited</h5>

          <p>
            scholarahead.com administers and operates the www.scholarahead.com
            Site from a location in Highland Park, Illinois, USA; other
            scholarahead.com sites may be administered and operated from various
            other locations. Although the Site is accessible worldwide, not all
            features, products or services discussed, referenced, provided or
            offered through or on the Site are available to all persons or in
            all geographic locations, or appropriate or available for use
            outside the United States. scholarahead.com reserves the right to
            limit, in its sole discretion, the provision and quantity of any
            feature, product or service to any person or geographic area. Any
            offer for any feature, product or service made on the Site is void
            where prohibited. If you choose to access the Site from outside the
            United States, you do so by your own initiative and you are solely
            responsible for complying with applicable local laws.
          </p>
        </div>
    );
  }
}

export default TermsOfUse