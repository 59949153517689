import React from "react";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { connect } from "react-redux";
import "./Navbar.css";

const NavBar = props => {
  const { auth, profile } = props;
  const links = auth.uid ? (
    <SignedInLinks profile={profile} />
  ) : (
    <SignedOutLinks />
  );
  return (
    <header>
      <div class="navbar-fixed">
        <nav>
          <div class="nav-wrapper">
              <a href="/search" class="brand-logo  teal-text"><i class="material-icons">school</i><h6 class="strong">Scholar Ahead</h6></a>
              <a
                href="#"
                class="sidenav-trigger teal-text"
                data-target="mobile-menu"
              >
                <i class="material-icons">menu</i>
              </a>
              <ul class="right hide-on-med-and-down">{links}</ul>
          </div>
        </nav>
      </div>
      
      <ul class="sidenav" id="mobile-menu">
          {links}
      </ul>
    </header>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(NavBar);
