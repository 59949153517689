 import firebase from 'firebase/app'
 import 'firebase/firestore' 
 import 'firebase/auth'

 // Initialize Firebase
 var config = {
  apiKey: "AIzaSyACJiy5NXiQHdBrXZoq-GnhdtqItQ6weOA",
  authDomain: "opportunities-714cb.firebaseapp.com",
  databaseURL: "https://opportunities-714cb.firebaseio.com",
  projectId: "opportunities-714cb",
  storageBucket: "opportunities-714cb.appspot.com",
  messagingSenderId: "428004374934"
};
firebase.initializeApp(config);
firebase.firestore().settings({timestampsInSnapshots: true})

export default firebase;