import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import UpcomingOpportunitySummary from "./UpcomingOpportunitySummary";

const UpcomingList = ({
  opportunities,
  getNextEvents,
  loading,
  moreEvents,
  isOwner
}) => {
  return (
    <div class="row" className="project-list section">
      {opportunities ? <p>{opportunities.length + " listed"}</p> : null}

      <InfiniteScroll
        pageStart={0}
        loadMore={getNextEvents}
        hasMore={!loading && moreEvents}
        initialLoad={false}
      >
        <div class="row">
          {opportunities &&
            opportunities.map(opportunity => {
              return (
                <div class="col">
                  <UpcomingOpportunitySummary
                    key={opportunity.id}
                    opportunity={opportunity}
                    isOwner={isOwner}
                  />
                  {/* here add extra input and button to add a day */}
                </div>
              );
            })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default UpcomingList;
