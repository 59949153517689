import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Box } from "gestalt";
import { createOfficialOpportunity } from "../../../Store/actions/projectActions";

import "gestalt/dist/gestalt.css";
import DraftOpportunityList from "./DraftOpportunityList";
import { Redirect } from "react-router-dom";
import UpcomingOpportunitySummary from "./UpcomingOpportunitySummary";
import UpcomingList from "./UpcomingList";

class UpcomingPage extends Component {
  state = {
    location: "",
    discipline: [],
    grade: "",
    type: []
  };

  render() {
    const { auth, opportunities, profile } = this.props;

    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }

    if (!profile.isEmpty && !profile.owner) {
      return <Redirect to="/search" />;
    }
    let unreviewedOpportunities;

    let isOwner = profile.owner || profile.reviewer || profile.contributor
    if (opportunities) {
      if (profile.reviewer) {
        //filter out all opportunities that already have a logo
        unreviewedOpportunities = opportunities.filter(opportunity => {
          return !opportunity.logo;
        });
      } else {
        //filter out all opportunities that have already been reviewed
        unreviewedOpportunities = opportunities.filter(opportunity => {
          return !opportunity.grades.includes("college sophomore") 
          && !opportunity.grades.includes("college freshman") && !opportunity.grades.includes("college junior")
          && !opportunity.grades.includes("college senior");
        });
      }
    }
    return (
      <div>
        <Box
          color="white"
          shape="rounded"
          padding={3}
          display="flex"
          direction="row"
        >
          <Box
            color="white"
            shape="rounded"
            padding={3}
            display="flex"
            direction="column"
          />
          <div className="dashboard container">
            <div className="row">
              <div className="col s12 m12">
                <UpcomingList opportunities={unreviewedOpportunities} isOwner = {isOwner} />{" "}
              </div>
            </div>
          </div>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    opportunities: state.firestore.ordered.opportunities,
    auth: state.firebase.auth,
    firebase: state.firebase,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOfficialOpportunity: project =>
      dispatch(createOfficialOpportunity(project))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ 
      collection: "opportunities",
      where: ["dueDateU", "<", Date.now() + 2629800000],
      orderBy: ["dueDateU", "asc"], 
    }])
)(UpcomingPage);
