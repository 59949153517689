import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../Store/actions/authActions";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";


class SignIn extends Component {
  state = {
    email: "",
    password: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };

  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to="/search" />;
    return (
      <div>
        <div className="container" style={{ minHeight: 300 }}>
          <form onSubmit={this.handleSubmit} className="white">
            <h4 className="grey-text text-darken-3 center">Sign In</h4>

            <h6 class="center">
                Don't have an account yet? <NavLink to="/signUp">Sign Up!</NavLink> It's easy and free. 
              </h6>

            <div className="input-field">
              <i class="material-icons prefix">email</i>
              <label htmlFor="email">Email</label>
              <input type="email" id="email" onChange={this.handleChange} />
            </div>

            <div className="input-field">
              <i class="material-icons prefix">vpn_key</i>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                onChange={this.handleChange}
              />
            </div>

            <div className="input-field">
              <button
                class="waves-effect waves-light btn-large z-depth-3"
                onSubmit={this.handleSubmit}
              >
                Login
              </button>
              <div className="red-text center">
                {authError ? <p>{authError}</p> : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
