import React from "react";
import OpportunitySummary from "./OpportunitySummary";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";

const OpportunityList = ({
  opportunities,
  getNextEvents,
  loading,
  moreEvents,
  isOwner
}) => {
  return (
    <div class="row" className="project-list section">
      <InfiniteScroll
        pageStart={0}
        loadMore={getNextEvents}
        hasMore={!loading && moreEvents}
        initialLoad={false}
      >
        <div class="row">
          {opportunities &&
            opportunities.map(opportunity => {
              return (
                <div class="col s4">
                  <Link to={"/opportunities/" + opportunity.id}>
                    <OpportunitySummary
                      key={opportunity.id}
                      opportunity={opportunity}
                      isOwner={isOwner}
                    />
                    {/* here add extra input and button to add a day */}
                  </Link>
                </div>
              );
            })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default OpportunityList;
