import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { compose } from "redux";
import {
  connectHits,
  connectSearchBox,
  connectPagination,
} from "react-instantsearch-dom";

import { Link } from "react-router-dom";
import moment from "moment";
import {
  InstantSearch,
  Stats,
  RefinementList,
  ScrollTo,
  HitsPerPage,
  Pagination,
  RangeInput,
  ToggleRefinement,
  ClearRefinements,
} from "react-instantsearch/dom";
import React, { Component } from "react";
import DropdownRefinementList from "./Dropdown/DropDownRefinementList";
import "gestalt/dist/gestalt.css";
import "./Dashboard.css";
import OpportunityDetails from "../Projects/OpportunityDetails";
import { saveState } from "../Projects/ReviewState/localStorage";

const cx = (label) => `ais-DropdownRefinementList-${label}`;

const Hits = ({ hits }) => (
  <div class="row s12 m12">
    {hits.map((hit) => (
      <div class="col s6 m4 center">
        <Route
          path="/opportunities/:programName/:id"
          component={OpportunityDetails}
        />
        <Link
          target="_blank"
          class="black-text"
          to={{
            pathname:
              "/opportunities/" +
              hit.programName.replace(/ /g, "-") +
              "/" +
              hit.objectID,
            opportunity: hit,
          }}
          onClick={saveState(hit)}
        >
          <SmallScreenCard info={hit} />
          <LargeScreenCard info={hit} />
        </Link>
      </div>
    ))}
  </div>
);

const CustomHits = connectHits(Hits);

const SearchBoxy = ({ currentRefinement, isSearchStalled, refine }) => {
  var currentSearch;
  return (
    <div class="input-field" style={{ paddingTop: 60 }}>
      <input
        class="white"
        id="search"
        type="search"
        required
        placeholder="Search our programs..."
        onChange={(event) => {
          currentSearch = event.currentTarget.value;
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            refine(currentSearch);
          }
        }}
        style={{
          borderRadius: 3,
          paddingLeft: 50,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      />

      <i
        style={{ marginTop: 75 }}
        class="material-icons"
        onClick={(event) => {
          event.preventDefault();
          refine(currentSearch);
        }}
      >
        search
      </i>
    </div>
  );
};

const PaginationC = ({ currentRefinement, nbPages, refine, createURL }) => (
  <ul>
    {new Array(nbPages).fill(null).map((_, index) => {
      if (index <= 25) {
        const page = index + 1;
        const style = {
          fontWeight: currentRefinement === page ? "bold" : "",
        };

        return (
          <li key={index}>
            <a
              href={createURL(page)}
              style={style}
              onClick={(event) => {
                event.preventDefault();
                refine(page);
              }}
            >
              {page}
            </a>
          </li>
        );
      }
    })}
  </ul>
);

const SmallScreenCard = (props) => (
  <div
    class="card-panel hide-on-med-and-up"
    style={{
      height: 350,
      borderRadius: 10,
      overflow: "hidden",
    }}
  >
    <div
      class="teal lighten-3"
      style={{
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={
          props.info.logoUrl
            ? props.info.logoUrl
            : props.info.logo
            ? props.info.logo
            : null
        }
        style={{ maxWidth: 100, maxHeight: 100 }}
      />
    </div>
    <h6 class="center teal-text darken-3">{props.info.programName}</h6>
    <p class="green-text darken-2">
      <strong>{props.info.organizationName}</strong>
    </p>

    {/* <i>
      <strong>Deadline: {moment(props.info.dueDate).format("MMM Do")}</strong>
    </i> */}
  </div>
);

const LargeScreenCard = (props) => (
  <div
    class="card-panel hide-on-small-only"
    style={{
      height: 390,
      borderRadius: 10,
      overflow: "hidden",
    }}
  >
    <div
      class="teal lighten-3"
      style={{
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={
          props.info.logoUrl
            ? props.info.logoUrl
            : props.info.logo
            ? props.info.logo
            : null
        }
        style={{ maxWidth: 100, maxHeight: 100 }}
      />
    </div>
    <h6 class="center teal-text darken-3">{props.info.programName}</h6>
    <p class="green-text darken-2">
      <strong>{props.info.organizationName}</strong>
    </p>

    <div
      style={{
        height: "4em",
        lineHeight: "1em",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {props.info.programDescription}
    </div>
    <br />
    {/* <i>
      <strong>Deadline: {moment(props.info.dueDate).format("MMM Do")}</strong>
    </i> */}
  </div>
);

const CustomPagination = connectPagination(PaginationC);

const CustomSearchBox = connectSearchBox(SearchBoxy);

const SideBar = () => (
  <div class="row">
    <ToggleRefinement attribute="online" label="Online for 2021" value={true} />
    <ToggleRefinement attribute="free" label="Free of Charge" value={true} />
    <ToggleRefinement
      attribute="scholarships"
      label="Offer Scholarships"
      value={true}
    />
    <ToggleRefinement attribute="paid" label="Pays Students" value={true} />
    <ToggleRefinement attribute="women" label="Girls/Women" value={true} />
    <ToggleRefinement
      attribute="minorities"
      label="Underrepresented Students"
      value={true}
    />
    <ToggleRefinement
      attribute="lowIncome"
      label="Low Income Students"
      value={true}
    />
    <br />

    <div class="teal center white-text">
      <ClearRefinements />
    </div>
  </div>
);

const SideBarPop = () => (
  <div class="row">
    <h6>
      <strong>Financing:</strong>{" "}
    </h6>
    <ToggleRefinement attribute="online" label="Online for 2021" value={true} />
    <ToggleRefinement attribute="free" label="Free of Charge" value={true} />
    <ToggleRefinement
      attribute="scholarships"
      label="Offer Scholarships"
      value={true}
    />
    <ToggleRefinement attribute="paid" label="Pays Students" value={true} />
    <h6>
      <strong>Focus On:</strong>{" "}
    </h6>

    <ToggleRefinement attribute="women" label="Girls/Women" value={true} />
    <ToggleRefinement
      attribute="minorities"
      label="Underrepresented Students"
      value={true}
    />
    <ToggleRefinement
      attribute="lowIncome"
      label="Low Income Students"
      value={true}
    />

    <h6 class="teal-text">Grades</h6>
    <RefinementList hoverable attribute={"grades"} limit="20" />
    <h6 class="teal-text">Discipline</h6>
    <RefinementList hoverable attribute={"discipline"} limit="20" />

    <h6 class="teal-text">Locations</h6>
    {/* <RefinementList hoverable attribute={"locations"} limit="20" /> */}
    <h6 class="teal-text">Type</h6>
    <RefinementList hoverable attribute={"type"} limit="20" />

    <br />

    <div class="row">
      <div class="col s6 ">
        <div class="teal  white-text">
          <ClearRefinements />
        </div>
      </div>
    </div>
  </div>
);

const FilterModal = () => (
  <div>
    <div
      class="waves-effect waves-light white teal-text  modal-trigger"
      href="#modal1"
      style={{ marginTop: "16px" }}
    >
      <button class="waves-effect waves-green teal white-text btn">
        {" "}
        Filter Programs
      </button>
    </div>

    <div id="modal1" class="modal">
      <div class="modal-content white">
        <h5 class="teal-text center">Filter Results</h5>
        <SideBarPop />
      </div>
      <div class="modal-footer">
        <button class="modal-close waves-effect waves-green teal white-text btn">
          Done
        </button>
      </div>
    </div>
  </div>
);
const Content = () => (
  <div>
    <div class="info">
      <Stats />
    </div>
    <ScrollTo>
      <CustomHits />
    </ScrollTo>
  </div>
);

class SearchDashboard extends Component {
  componentDidMount() {
    window.$(".sidenav").sidenav();
    window.$(".modal").modal();
  }

  render() {
    return (
      <div className="dashboard teal lighten-4">
        <InstantSearch
          apiKey="8bf64ae9b103df9b1194004ed06ad4cb"
          appId="WLO4TIFO6V"
          indexName="programs"
        >
          <header className="header mid-header">
            <div class="row">
              <div class="col s10">
                <CustomSearchBox />
              </div>
            </div>
          </header>
          <main class="white">
            <div
              class="col hide-on-small-only"
              style={{ display: "flex", justifyItems: "flex-right" }}
            >
              <DropdownRefinementList
                hoverable
                attribute={"grades"}
                limit="30"
              />
              <DropdownRefinementList hoverable attribute={"type"} />
              <DropdownRefinementList
                hoverable
                attribute={"locations"}
                limit="52"
              />
              <DropdownRefinementList
                hoverable
                attribute={"discipline"}
                limit="30"
              />
            </div>

            <div class="row hide-on-med-and-up" style={{ marginLeft: "38%" }}>
              <FilterModal />
            </div>

            <div class="row">
              <div class="col s12 l3">
                <div class="col hide-on-med-and-down">
                  <SideBar />
                  <RangeInput
                    className="hide"
                    style={{ display: "none" }}
                    attribute="dueDateU"
                    // min={Date.now() - 86400000}
                  />
                </div>
              </div>

              <div class="col s12 l9">
                <Content />
                <div class="center" className="pagination">
                  <CustomPagination />
                  <HitsPerPage
                    defaultRefinement={12}
                    items={[
                      { value: 12, label: "Show 12 hits" },
                      { value: 24, label: "Show 24 hits" },
                    ]}
                  />
                </div>
              </div>
            </div>
          </main>
        </InstantSearch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    firebase: state.firebase,
    profile: state.firebase.profile,
    requesting: state.firestore.requesting,
  };
};

export default compose(connect(mapStateToProps))(SearchDashboard);
