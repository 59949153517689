import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const UpcomingOpportunitySummary = ({ opportunity, isOwner }) => {
  return (
    <div>
        <img
            src={opportunity.logo ? opportunity.logo : opportunity.logoUrl ? opportunity.logoUrl : null}
            style={{ maxWidth: 150, maxHeight: 150 }}
          />
      <h5 class="green-text darken-2">
        <strong>{opportunity.organizationName}</strong>
      </h5>
      <h6 class="teal-text darken-3">{opportunity.programName}</h6>

      <div class="black-text">{opportunity.programDescription}</div>

      <p class="teal-text darken-3">
        <strong>Grades: </strong>
        {opportunity.grades.join(", ")}
      </p>
      <p class="teal-text darken-3">
        <strong>Location:</strong> {opportunity.locations.join(", ")}
      </p>

      <p class="teal-text darken-3">
        <strong>Program Website: </strong>
        <a href={opportunity.programUrl}>{opportunity.programUrl}</a>
      </p>

      <p class="teal-text darken-3">
        <strong>Scholar Ahead Link:</strong>{" "}
        <a href={"http://app.scholarahead.com/opportunities/" + opportunity.id}>
          http://app/scholarahead.com/opportunities/{opportunity.id}
        </a>
        <Link to={"/opportunities/" + opportunity.id} />
      </p>

      {opportunity.cost ? (
        <p class="teal-text darken-3">
          <strong>Cost: </strong>
          {opportunity.cost}
        </p>
      ) : null}

      {opportunity.paid ? <p class="teal-text darken-3">Pays Students</p> : null}

      {opportunity.scholarships && !opportunity.free ? <p class="teal-text darken-3">Offers Scholarships</p> : null}

      {opportunity.free ? <p class="teal-text darken-3">Cost: Free</p> : null}

      {/* <p class="teal-text darken-3">
        <strong>Deadline: </strong>
        {moment(opportunity.dueDate).format("MMMM Qo, YYYY")}
      </p> */}
      <br />
    </div>
  );
};

export default UpcomingOpportunitySummary;
