import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../Store/actions/authActions";

const SignedInLinks = props => {
  return (
    <ul className="right">
      <li>
        <NavLink class="teal-text" to="/Saved">
          Saved
        </NavLink>
      </li>
      <li>
          <a class="teal-text"href={"http://scholarahead.com/about"}>About</a>
      </li>
      <li>
          <a class="teal-text"href={"http://scholarahead.com/blog"}>Blog</a>
      </li>
      <li></li>
      <li>
        <NavLink class="teal-text" to="/profilePage">
          {" "}
          <span class="hide-on-large-only">Profile</span>{" "}
          <a class="btn-floating btn-med waves-effect waves-light teal hide-on-med-and-down">
            <i class="material-icons">person_outline</i>
          </a>
        </NavLink>
      </li>
      <li>
        <NavLink class="teal-text" to="/AddProgram">
          Add Program
        </NavLink>
      </li>
      <li>
          <a class="teal-text" href="/search" onClick={props.signOut}>
            Log Out
          </a>
      </li>
    </ul>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => {
      //TODO Madey redirect to sign in
      dispatch(signOut())
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SignedInLinks);
